import React from 'react';

export default function CloseModalIcon() {
	return (
		<svg
			width={41}
			height={41}
			viewBox="0 0 41 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_1294_5367)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20 37.3333C29.5729 37.3333 37.3333 29.5729 37.3333 20C37.3333 10.4271 29.5729 2.66667 20 2.66667C10.4271 2.66667 2.66667 10.4271 2.66667 20C2.66667 29.5729 10.4271 37.3333 20 37.3333ZM20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
					fill="white"
				/>
			</g>
			<g filter="url(#filter1_d_1294_5367)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M27.6095 12.3905C27.0888 11.8698 26.2446 11.8698 25.7239 12.3905L12.3905 25.7239C11.8698 26.2446 11.8698 27.0888 12.3905 27.6095C12.9112 28.1302 13.7554 28.1302 14.2761 27.6095L27.6095 14.2761C28.1302 13.7554 28.1302 12.9112 27.6095 12.3905Z"
					fill="white"
				/>
			</g>
			<g filter="url(#filter2_d_1294_5367)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.3905 12.3905C12.9112 11.8698 13.7554 11.8698 14.2761 12.3905L27.6095 25.7239C28.1302 26.2446 28.1302 27.0888 27.6095 27.6095C27.0888 28.1302 26.2446 28.1302 25.7239 27.6095L12.3905 14.2761C11.8698 13.7554 11.8698 12.9112 12.3905 12.3905Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1294_5367"
					x={0}
					y={0}
					width={41}
					height={41}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx={1} dy={1} />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1294_5367"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1294_5367"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_d_1294_5367"
					x={12}
					y={12}
					width={17}
					height={17}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx={1} dy={1} />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1294_5367"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1294_5367"
						result="shape"
					/>
				</filter>
				<filter
					id="filter2_d_1294_5367"
					x={12}
					y={12}
					width={17}
					height={17}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx={1} dy={1} />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1294_5367"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1294_5367"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
}
