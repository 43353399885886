import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../UI/CustomTable';
import { ActionButtonWrapper, DisplayNameWrapper } from '../../UserList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { MetaverseInternalClient } from 'metaverse-js/lib/internal/MetaverseInternalClient';
import { format } from 'date-fns';
import { UserIdentityAdminClient } from 'user-identity-js';
import Copy from '../../UI/Copy';
import { convertStatusToCapitalized, shortenAddress } from '../../../utils';
import styled from 'styled-components';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';
import { config } from '../../../config';

const Status = styled.div<{ status: TransactionState }>(
	(props) => `
     color: ${
				props.status === 'COMPLETED'
					? '#21FF0E'
					: props.status === 'FAILED' ||
					  props.status === 'REJECTED' ||
					  props.status === 'INVALID'
					? '#FF0000'
					: props.status === 'PROCESSING'
					? '#FFA500'
					: '#737283'
			}
`
);
interface DataType {
	token: string;
	time: string;
	toAccount: string;
	amount: string;
	status: string;
	transaction_hash: string;
	fromAccount: string;
}

const columns: ColumnsType<DataType> = [
	{
		title: 'Token',
		dataIndex: 'token',
		key: 'token',
	},
	{
		title: 'Time',
		dataIndex: 'time',
		key: 'time',
	},
	{
		title: 'From',
		key: 'fromAccount',
		dataIndex: 'fromAccount',
		render: (text) => (
			<DisplayNameWrapper>
				<p>{shortenAddress(text)}</p>
				<ActionButtonWrapper>
					{!!text && <Copy text={text} />}
				</ActionButtonWrapper>
			</DisplayNameWrapper>
		),
	},
	{
		title: 'To',
		key: 'toAccount',
		dataIndex: 'toAccount',
		render: (text) => (
			<DisplayNameWrapper>
				<p>{text}</p>
				<ActionButtonWrapper>
					{text && <Copy text={text} />}
				</ActionButtonWrapper>
			</DisplayNameWrapper>
		),
	},
	{
		title: 'Amount',
		key: 'amount',
		dataIndex: 'amount',
	},
	{
		title: 'Status',
		key: 'status',
		dataIndex: 'status',
		render: (text) => (
			<Status status={text}>{convertStatusToCapitalized(text)}</Status>
		),
	},
	{
		title: 'Transaction Hash',
		key: 'transaction_hash',
		dataIndex: 'transaction_hash',
		render: (text) => (
			<div
				style={{
					cursor: 'pointer',
					color: 'blue',
					textDecoration: 'underline',
				}}
				onClick={() => window.open(`${config.bscsan_url}/tx/${text}`)}
			>
				{shortenAddress(text)}{' '}
			</div>
		),
	},
];

interface TransactionTableProps {
	txParams?: TransactionQueryRequest;
}
const TransactionTable: React.FC<TransactionTableProps> = ({
	txParams,
}: TransactionTableProps) => {
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [transactions, setTransactions] = React.useState<DataType[]>([]);
	const [params, setParams] = useState({
		page: 1,
		pageSize: 10,
	});
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (metaverseJs && userIdentity) {
			getTransactions(metaverseJs, userIdentity, { ...params, ...txParams });
		}
	}, [metaverseJs, params, txParams]);
	const getTransactions = async (
		metaverseJs: MetaverseInternalClient,
		userIdentity: UserIdentityAdminClient,
		params?: any
	) => {
		try {
			const res = await metaverseJs.getTransactions({
				type: 'DEPOSIT',
				limit: params.pageSize,
				offset: (params.page - 1) * params.pageSize,
				...params,
			});
			const ids = await userIdentity.getUsers({
				ids: res.data.map((tx) => tx.toUser).filter((id) => id) as string[],
			});
			setTransactions(
				res.data.map((tx) => ({
					...tx,
					token: tx.assetSymbol || '',
					time: tx.createdAt
						? format(new Date(tx.createdAt * 1000), 'MM/dd/yyyy hh:mm a')
						: '',
					toAccount:
						ids.data.find((user) => user.id === tx.toUser)?.email || '',
					amount: tx.assetAmount || '',
					fromAccount: tx.fromAccount || '',
					status: tx.state || '',
					transaction_hash: tx.onchainHash || '',
				}))
			);
			setTotal(res.meta.total);
		} catch (error) {
			console.log('getTransactions', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<CustomTable
			columns={columns}
			dataSource={transactions}
			onChange={onChangeTable}
			pageSize={params.pageSize}
			total={total}
		/>
	);
};

export default TransactionTable;
