export const ROUTES = {
	ERROR: '*',
	HOME: '/',
	LOGIN: '/login',
	HOT_WALLETS: '/hot-wallets',
	USER_LIST: '/user-list',
	TRANSACTIONS: '/transactions',
	KYC: '/kyc',
	KYC_DETAIL: '/kyc/:id',
	AD_MANAGEMENT: '/ad-management',
	AD_MANAGEMENT_DETAIL: '/ad-management/:id',
	BUY_SELL_CRYPTO: '/buy-sell-crypto',
	BUY_SELL_CRYPTO_DETAIL: '/buy-sell-crypto/:id',
	GAME_TRIAL_PACKAGE: '/game-trial-package',
	CLAIM: '/claim',
	GAME_SHOW: '/game-show',
	FRANCHISE: '/franchise',
	GAME_MANAGEMENT: '/game-management',
	REPORTS: '/reports',
	CONFIG: '/config',
	WALLET_CONFIG: '/wallet-config',
};
