import React from 'react';
import CustomSelect from '../UI/CustomSelect';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import CustomRangePicker from '../UI/CustomRangePicker';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
`;

const Options = [
	{
		label: 'option 1',
		value: '1',
	},
	{
		label: 'option 2',
		value: '2',
	},
	{
		label: 'option 3',
		value: '3',
	},
];

const TransactionFilter = () => {
	return (
		<Wrapper>
			<Form layout="vertical">
				<Filter>
					<CustomRangePicker label="Time" />
					<CustomSelect
						label="Mode"
						options={Options}
						defaultValue={Options[0].value}
					/>
				</Filter>
			</Form>
			<Button type="primary" ghost>
				Export Excel
			</Button>
		</Wrapper>
	);
};

export default TransactionFilter;
