export default function DropdownIcon(props: any) {
	return (
		<svg
			{...props}
			width={17}
			height={16}
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.4175 5C12.2653 5 12.7285 5.98886 12.1857 6.64018L9.32077 10.0781C8.92098 10.5579 8.18412 10.5579 7.78433 10.0781L4.91937 6.64018C4.3766 5.98886 4.83976 5 5.68759 5H11.4175Z"
				fill="#737283"
			/>
		</svg>
	);
}
