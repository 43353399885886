import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

interface PrimaryButtonProps extends ButtonProps {
	children: React.ReactNode;
}

const StyledButton = styled(Button)`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #181a20;
	height: 48px;
	border: none;
	padding: 0 10px;
`;

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
	children,
	...props
}: PrimaryButtonProps) => {
	return <StyledButton {...props}>{children}</StyledButton>;
};

export default PrimaryButton;
