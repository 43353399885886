import React from 'react';
import styled from 'styled-components';
import TitlePage from '../../UI/TitlePage';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';

const TitleWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	p {
		font-weight: 500;
		font-size: 18px;
		line-height: 48px;
		color: #ffffff;
		margin: 0;
	}
	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #737283;
	}
`;
const TypeTag = styled('div')<{ type: AdOrderType | undefined }>`
	border: ${(props) =>
		`${
			props.type === AdOrderType.BUY ? '1px solid #03a66d' : '1px solid #CF304A'
		}`};
	border-radius: 5px;
	padding: 5px 20px;
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: ${(props) =>
			`${props.type === AdOrderType.BUY ? '#03a66d' : '#CF304A'}`};
		margin: 0;
	}
`;
const CryptoWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 5px;
`;
const SymbolImg = styled('img')`
	width: 20px;
`;

interface Props {
	type: AdOrderType | undefined;
	cryptoCurrency: string;
	fiatCurrency: string;
}
const Title: React.FC<Props> = ({
	type,
	cryptoCurrency,
	fiatCurrency,
}: Props) => {
	return (
		<TitlePage
			title={
				<TitleWrapper>
					<p>AD Management:</p>
					<TypeTag type={type}>
						<p>{type?.split('_')[1]}</p>
					</TypeTag>
					<CryptoWrapper>
						<SymbolImg
							src={`/images/tokens/${cryptoCurrency.toLocaleLowerCase()}.svg`}
							alt={cryptoCurrency}
						/>
						<p>{cryptoCurrency}</p>
					</CryptoWrapper>
					<div>
						<p>
							<span>with</span> {fiatCurrency}
						</p>
					</div>
				</TitleWrapper>
			}
		/>
	);
};

export default Title;
