import { Form } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CustomSelect from '../UI/CustomSelect';
import {
	AdOrderAssetSymbol,
	ExecuteOrderStatus,
	ExecuteOrderType,
	QueryExecuteOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import { FieldData } from 'rc-field-form/es/interface';

const FilterWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const CryptoOptions = Object.values(AdOrderAssetSymbol).map((assetSymbol) => {
	return {
		label: assetSymbol,
		value: assetSymbol,
	};
});

const StatusOptions = Object.values(ExecuteOrderStatus).map((status) => {
	return {
		label: status.replaceAll('_', ' ').replace('exorder', ''),
		value: status,
	};
});

const TypeOptions = Object.values(ExecuteOrderType).map((type) => {
	return {
		label: type.replace('exorder_', ''),
		value: type,
	};
});

interface PropsType {
	handleFilter: (query: QueryExecuteOrder) => void;
}

export default function Filter({ handleFilter }: PropsType) {
	const [form] = Form.useForm();
	const onFinish = (values: any) => {
		console.log(values);
	};

	const handleFieldsChange = (
		changedFields: FieldData[],
		allFields: FieldData[]
	) => {
		const query = form.getFieldsValue() as QueryExecuteOrder;
		if (!query.assetSymbol) {
			delete query['assetSymbol'];
		}
		if (!query.types) {
			delete query['types'];
		}
		if (!query.statuses) {
			delete query['statuses'];
		}
		handleFilter(query);
	};

	// useEffect(() => {}, []);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			// initialValues={{
			// 	assetSymbol: CryptoOptions[0].value,
			// 	types: TypeOptions[0].value,
			// 	statuses: StatusOptions[0].value,
			// }}
			onFieldsChange={handleFieldsChange}
		>
			<FilterWrapper>
				<CustomSelect
					label={'Cryptos'}
					name="assetSymbol"
					options={CryptoOptions}
					selectProps={{
						size: 'large',
						allowClear: true,
						style: {
							width: 200,
						},
					}}
					// defaultValue={CryptoOptions[0].value}
				/>

				<CustomSelect
					label="Type"
					options={TypeOptions}
					// defaultValue={TypeOptions[0].value}
					name="types"
					selectProps={{
						size: 'large',
						allowClear: true,
						style: {
							width: 200,
						},
					}}
				/>

				<CustomSelect
					label="Status"
					options={StatusOptions}
					// defaultValue={StatusOptions[0].value}
					name="statuses"
					selectProps={{
						size: 'large',
						allowClear: true,
						style: {
							width: 200,
						},
					}}
				/>
			</FilterWrapper>
		</Form>
	);
}
