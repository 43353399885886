import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserIdentityAdminClient } from 'user-identity-js';
import { getEnv } from '../../utils';
import { MetaverseInternalClient } from 'metaverse-js/lib/internal/MetaverseInternalClient';
import { P2PTradingAdminClient } from 'skychain-p2p-trading-js';
import { DecentralizedWalletClient } from 'skychain-decentralized-wallet-js';
interface SystemState {
	userIdentity: UserIdentityAdminClient | null;
	metaverseJs: MetaverseInternalClient | null;
	p2pTrading: P2PTradingAdminClient | null;
	decentralizedWallet: DecentralizedWalletClient | null;
}

const initialState = {
	userIdentity: new UserIdentityAdminClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}identity/admin`,
	}),
	metaverseJs: new MetaverseInternalClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}metaverse/admin`,
	}),
	p2pTrading: new P2PTradingAdminClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}p2p/admin`,
	}),
	decentralizedWallet: new DecentralizedWalletClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}decentralized-wallet/admin`,
	}),
} as SystemState;

const systemSlice = createSlice({
	name: 'systemSlice',
	initialState,
	reducers: {
		setTokenId(state, action: PayloadAction<string>) {
			state.userIdentity?.setTokenId(action.payload);
			state.metaverseJs?.setTokenId(action.payload);
			state.p2pTrading?.setTokenId(action.payload);
			state.decentralizedWallet?.setTokenId(action.payload);
		},
	},
});

export const { setTokenId } = systemSlice.actions;
export default systemSlice.reducer;
