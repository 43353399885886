import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomTable from '../../UI/CustomTable';

import { ActionButtonWrapper, DisplayNameWrapper } from '../../UserList';
import { Button, notification } from 'antd';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { UserIdentityAdminClient } from 'user-identity-js';
import { RootState } from '../../../redux/store';
import { MetaverseInternalClient } from 'metaverse-js/lib/internal/MetaverseInternalClient';
import Copy from '../../UI/Copy';
import { convertStatusToCapitalized, shortenAddress } from '../../../utils';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';

const Row = styled('div')`
	display: flex;
	gap: 10px;
`;

const StyledButton = styled(Button)`
	:where(
			.css-dev-only-do-not-override-178vi8q
		).ant-btn-default.ant-btn-background-ghost {
		color: #ffffff33;
		background-color: transparent;
		border-color: #ffffff33;
		box-shadow: none;
	}
	:where(.css-dev-only-do-not-override-178vi8q).ant-btn-primary.ant-btn-background-ghost: disabled {
		color: #ffffff33;
		border: 1px solid #ffffff33;
	}
`;
const Status = styled.div<{ status: TransactionState }>(
	(props) => `
     color: ${
				props.status === 'COMPLETED'
					? '#21FF0E'
					: props.status === 'FAILED' ||
					  props.status === 'REJECTED' ||
					  props.status === 'INVALID'
					? '#FF0000'
					: props.status === 'PROCESSING'
					? '#FFA500'
					: '#737283'
			}
`
);

interface DataType {
	token: string;
	time: string;
	toAccount: string;
	amount: string;
	status: string;
	transaction_hash: string;
	fromAccount: string;
	network: string;
	id: string;
}

const key = 'withdraw-progress';
interface TransactionTableProps {
	txParams?: TransactionQueryRequest;
}
const WithdrawProgressTable: React.FC<TransactionTableProps> = ({
	txParams,
}: TransactionTableProps) => {
	const [api, contextHolder] = notification.useNotification();
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [transactions, setTransactions] = React.useState<DataType[]>([]);
	const [params, setParams] = useState({
		page: 1,
		pageSize: 10,
	});
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (metaverseJs && userIdentity) {
			getTransactions(metaverseJs, userIdentity, { ...params, ...txParams });
		}
	}, [metaverseJs, params, userIdentity, txParams]);
	const getTransactions = async (
		metaverseJs: MetaverseInternalClient,
		userIdentity: UserIdentityAdminClient,
		params?: any
	) => {
		try {
			const res = await metaverseJs.getTransactions({
				type: 'WITHDRAW',
				limit: params.pageSize,
				offset: (params.page - 1) * params.pageSize,
				...params,
			});
			const ids = await userIdentity.getUsers({
				ids: res.data.map((tx) => tx.fromUser).filter((id) => id) as string[],
			});
			setTransactions(
				res.data.map((tx) => ({
					...tx,
					id: tx.id || '',
					token: tx.assetSymbol || '',
					time: tx.createdAt
						? format(new Date(tx.createdAt * 1000), 'MM/dd/yyyy hh:mm a')
						: '',
					toAccount: tx.toAccount || '',
					amount: tx.assetAmount || '',
					fromAccount:
						ids.data.find((user) => user.id === tx.fromUser)?.email || '',
					status: tx.state || '',
					transaction_hash: tx.id || '',
					network: tx.toChain || '',
				}))
			);
			setTotal(res.meta.total);
		} catch (error) {
			console.log('getTransactions', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};

	const columns: ColumnsType<DataType> = [
		{
			title: 'Token',
			dataIndex: 'token',
			key: 'token',
		},
		{
			title: 'Network',
			key: 'network',
			dataIndex: 'network',
		},
		{
			title: 'Time',
			dataIndex: 'time',
			key: 'time',
		},
		{
			title: 'From',
			key: 'fromAccount',
			dataIndex: 'fromAccount',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'To',
			key: 'toAccount',
			dataIndex: 'toAccount',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{shortenAddress(text)}</p>
					<ActionButtonWrapper>
						{text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Amount',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (text) => (
				<Status status={text}>{convertStatusToCapitalized(text)}</Status>
			),
		},
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render(_, record) {
				return (
					<Row>
						<StyledButton
							disabled={record.status !== 'WAITING_FOR_APPROVAL'}
							type="primary"
							ghost
							onClick={() => approveTx(record.id)}
						>
							Approve
						</StyledButton>
						<StyledButton
							disabled={record.status !== 'WAITING_FOR_APPROVAL'}
							type="primary"
							ghost
							onClick={() => rejectTx(record.id)}
						>
							Reject
						</StyledButton>
					</Row>
				);
			},
		},
	];

	const approveTx = async (txId: string) => {
		if (!metaverseJs) return;
		try {
			const res = await metaverseJs.approveTransactionById(txId);
			console.log('approveTx', res);
			if (res) {
				api['success']({
					key,
					message: 'Approve transaction successfully',
				});
				if (!userIdentity) return;
				getTransactions(metaverseJs, userIdentity, { ...params, ...txParams });
			}
		} catch (error: any) {
			console.log('approveTx', error);
			api['error']({
				key,
				message: error?.response?.data?.message || 'Unknown error',
			});
		}
	};

	const rejectTx = async (txId: string) => {
		if (!metaverseJs) return;
		try {
			const res = await metaverseJs.rejectTransactionById(txId);
			if (res) {
				api['success']({
					key,
					message: 'Reject transaction successfully',
				});
				if (!userIdentity) return;
				getTransactions(metaverseJs, userIdentity, { ...params, ...txParams });
			}
		} catch (error: any) {
			api['error']({
				key,
				message: error?.response?.data?.message || 'Unknown error',
			});
		}
	};

	return (
		<>
			{contextHolder}
			<CustomTable
				columns={columns}
				dataSource={transactions}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				total={total}
			/>
		</>
	);
};

export default WithdrawProgressTable;
