import { Col, Row, notification } from 'antd';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ExecuteDetail } from '.';
import useModal from '../../../hooks/useModal';
import { RootState } from '../../../redux/store';
import CloseIcon from '../../Icons/CloseIcon';
import Copy from '../../UI/Copy';
import ActionOrderModal from './ActionOrderModal';
import Chat from './Chat';

const BodyWrapper = styled('div')`
	display: flex;
	gap: 40px;
`;
const InfoWrapper = styled('div')`
	width: 100%;
	max-width: 645px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	p {
		margin: 0;
	}
`;
const InfoRow = styled('div')`
	display: flex;
	gap: 10px;
`;
const FlexLeft = styled('div')`
	width: 257px;
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		color: #737283;
	}
`;
const FlexRight = styled('div')`
	width: calc(100% - 257px);
`;
const Mail = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #1c84e4;
`;
const PaymentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
const BankWrapper = styled('div')`
	position: relative;
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: 387px;
`;
const UserName = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
`;
const BankInfo = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #737283;
`;
const CloseIconWrapper = styled('div')`
	position: absolute;
	right: 15px;
	top: 15px;
	line-height: 0px;
	cursor: pointer;
`;
const ActionsWrapper = styled('div')`
	width: 100%;
`;
const CancelButton = styled('button')`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	background: #484756;
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #ffffff;
	cursor: pointer;
`;

const ApproveButton = styled('button')`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #181a20;
	cursor: pointer;
`;

export enum ACTION {
	CANCEL = 'cancel',
	MAKE_SUCCESS = 'make success',
}

export default function Body({
	executeDetail,
}: {
	executeDetail: ExecuteDetail;
}) {
	const p2pTrading = useSelector((state: RootState) => state.system.p2pTrading);
	const [api, contextHolder] = notification.useNotification();
	const { isOpen, toggle } = useModal();
	const [action, setAction] = useState<ACTION>();

	const handleCancel = () => {
		toggle();
		setAction(ACTION.CANCEL);
	};
	const handleApprove = () => {
		toggle();
		setAction(ACTION.MAKE_SUCCESS);
	};

	const handleSendReason = async (reason: string, action: ACTION) => {
		toggle();
		try {
			if (action === ACTION.CANCEL) {
				await p2pTrading?.cancelExecuteOrder(executeDetail.id, {
					reasonCancel: reason,
				});
			}
			if (action === ACTION.MAKE_SUCCESS) {
				await p2pTrading?.confirmExecuteOrder(executeDetail.id, {
					adminReason: reason,
				});
			}
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Something went wrong',
			});
			console.log(error);
		}
	};

	useEffect(() => {
		if (!isOpen) {
			setAction(undefined);
		}
	}, [isOpen]);

	return (
		<>
			{contextHolder}
			<BodyWrapper>
				<InfoWrapper>
					<InfoRow>
						<FlexLeft>
							<p>User:</p>
						</FlexLeft>
						<FlexRight>
							<Mail>{executeDetail.userInfo.email}</Mail>
						</FlexRight>
					</InfoRow>

					<InfoRow>
						<FlexLeft>
							<p>Fiat Amount:</p>
						</FlexLeft>
						<FlexRight>
							<p>
								{new BigNumber(executeDetail.amount).toFormat(4)}{' '}
								{executeDetail.fiatSymbol}
							</p>
						</FlexRight>
					</InfoRow>

					<InfoRow>
						<FlexLeft>
							<p>Price:</p>
						</FlexLeft>
						<FlexRight>
							<p>
								{new BigNumber(executeDetail.price).toFormat(4)}{' '}
								{executeDetail.assetSymbol}
							</p>
						</FlexRight>
					</InfoRow>

					{/* <InfoRow>
						<FlexLeft>
							<p>Quantity:</p>
						</FlexLeft>
						<FlexRight>
							<p>1.000 USDT</p>
						</FlexRight>
					</InfoRow> */}

					<InfoRow>
						<FlexLeft>
							<p>Created Date:</p>
						</FlexLeft>
						<FlexRight>
							<p>
								{format(
									new Date(executeDetail.createdAt * 1000),
									'dd/MM/yyyy HH:mm:ss'
								)}
							</p>
						</FlexRight>
					</InfoRow>

					<InfoRow>
						<FlexLeft>
							<p>Reference Message:</p>
						</FlexLeft>
						<FlexRight>
							<Copy text={executeDetail.referenceMessage || ''} isShowText />
						</FlexRight>
					</InfoRow>

					<InfoRow>
						<FlexLeft>
							<p>Pament Method:</p>
						</FlexLeft>
						<FlexRight>
							<PaymentWrapper>
								<BankWrapper>
									<BankInfo>
										{executeDetail.paymentMethodInfo?.bankName}
									</BankInfo>
									<UserName>
										{executeDetail.paymentMethodInfo?.fullName}
									</UserName>
									<BankInfo>
										{executeDetail.paymentMethodInfo?.bankAccountNumber}
									</BankInfo>

									{/* <CloseIconWrapper>
										<CloseIcon />
									</CloseIconWrapper> */}
								</BankWrapper>
							</PaymentWrapper>
						</FlexRight>
					</InfoRow>

					<InfoRow>
						<FlexLeft>
							<p>Status:</p>
						</FlexLeft>
						<FlexRight>
							<p>
								{executeDetail.status
									.replaceAll('_', ' ')
									.replace('exorder', '') || ''}
							</p>
						</FlexRight>
					</InfoRow>

					{/* <InfoRow>
						<FlexLeft>
							<p>Operation:</p>
						</FlexLeft>
						<FlexRight>
							<Copy text={'564163132123416354777'} />
						</FlexRight>
					</InfoRow> */}
					<ActionsWrapper>
						<Row gutter={20}>
							<Col span={10}>
								<CancelButton onClick={handleCancel}>Cancel Order</CancelButton>
							</Col>
							<Col span={14}>
								<ApproveButton onClick={handleApprove}>
									Make Success
								</ApproveButton>
							</Col>
						</Row>
					</ActionsWrapper>
				</InfoWrapper>

				<Chat
					counterpart={'gen***67@gmail.com'}
					status={'online'}
					groupId={'1231412ads'}
				/>
			</BodyWrapper>
			<ActionOrderModal
				isOpen={isOpen}
				toggle={toggle}
				handleSendReason={handleSendReason}
				action={action}
			/>
		</>
	);
}
