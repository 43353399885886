import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CloseModalIcon from '../Icons/CloseModalIcon';

const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		padding: 0px;
		border-radius: 10px;
		overflow: hidden;
	}
`;

const BodyWrapper = styled('div')`
	position: relative;
	width: 100%;
	height: max-content;
	display: flex;
	align-items: center;
	img {
		width: 100%;
		max-height: 70vh;
		height: auto;
		object-fit: contain;
	}
`;
const CloseWrapper = styled('div')`
	top: 20px;
	right: 20px;
	position: absolute;
	cursor: pointer;
`;

interface PropsType {
	isOpen: boolean;
	toggle: () => void;
	src: string;
}

export default function ExpandModal({ isOpen, toggle, src }: PropsType) {
	return (
		<ModalWrapper
			title={null}
			closable={false}
			centered
			open={isOpen}
			onCancel={toggle}
			footer={null}
			width={'1164px'}
		>
			<BodyWrapper>
				<img src={src} alt={src} />
				<CloseWrapper onClick={toggle}>
					<CloseModalIcon />
				</CloseWrapper>
			</BodyWrapper>
		</ModalWrapper>
	);
}
