import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import styled from 'styled-components';
import DropdownIcon from '../Icons/DropdownIcon';

interface CustomSelectProps extends Omit<FormItemProps, 'label'> {
	selectProps?: SelectProps;
	label?: string;
	options: DefaultOptionType[];
	defaultValue?: string;
}

const StyledSelect = styled(Select)<{ label?: string }>`
	[class='ant-select-selector'] {
		background-color: rgba(255, 255, 255, 0.05) !important;
		border: 1px solid rgba(255, 255, 255, 0.1) !important;
		border-radius: 4px !important;
	}
	[class='ant-select-selection-item'] {
		color: #fff !important;
	}

	.ant-select-selection-placeholder {
		color: #737283;
	}
`;
const Label = styled('div')`
	color: #737283;
`;
const CustomSelect: React.FC<CustomSelectProps> = ({
	label,
	selectProps,
	options,
	defaultValue,
	...props
}: any) => {
	return (
		<Form.Item {...props} label={label ? <Label>{label}</Label> : null}>
			<StyledSelect
				size="large"
				defaultValue={defaultValue}
				options={options}
				{...selectProps}
				suffixIcon={<DropdownIcon />}
			/>
		</Form.Item>
	);
};

export default CustomSelect;
