import { createBrowserRouter, redirect } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import HomePage from '../pages/home';
import { ROUTES } from './config';
import LoginPage from '../pages/login';
import { METANIEX_ADMIN } from '../constants';
import Users from '../pages/users';
import TransactionsPage from '../pages/transactions';
import KYCPage from '../pages/kyc';
import KycDetailPage from '../pages/kyc-detail';
import AdManagementPage from '../pages/ad-management';
import AdManagementDetail from '../components/AdManagement/Detail';
import BuySellCryptoPage from '../pages/buy-sell-crypto';
import BuySellCryptoDetail from '../components/BuySellCrypto/Detail';
import ReportPage from '../pages/report';
import HotWalletsPage from '../pages/hot-wallets';
import WalletConfigPage from '../pages/wallet-config';

export const router = createBrowserRouter([
	{
		element: <MainLayout />,
		loader: async () => {
			const user = localStorage.getItem(METANIEX_ADMIN);
			const jsonUser = user ? JSON.parse(user) : null;
			if (!jsonUser) {
				return redirect(ROUTES.LOGIN);
			}
			return jsonUser;
		},
		children: [
			{
				path: ROUTES.HOME,
				element: <HomePage />,
			},
			{
				path: ROUTES.USER_LIST,
				element: <Users />,
			},
			{
				path: ROUTES.HOT_WALLETS,
				element: <HotWalletsPage />,
			},
			{
				path: ROUTES.TRANSACTIONS,
				element: <TransactionsPage />,
			},
			{
				path: ROUTES.KYC,
				element: <KYCPage />,
			},
			{
				path: ROUTES.KYC_DETAIL,
				element: <KycDetailPage />,
			},
			{
				path: ROUTES.AD_MANAGEMENT,
				element: <AdManagementPage />,
			},
			{
				path: ROUTES.AD_MANAGEMENT_DETAIL,
				element: <AdManagementDetail />,
			},
			{
				path: ROUTES.BUY_SELL_CRYPTO,
				element: <BuySellCryptoPage />,
			},
			{
				path: ROUTES.BUY_SELL_CRYPTO_DETAIL,
				element: <BuySellCryptoDetail />,
			},
			{
				path: ROUTES.REPORTS,
				element: <ReportPage />,
			},
			{
				path: ROUTES.WALLET_CONFIG,
				element: <WalletConfigPage />,
			},
		],
	},
	{
		path: ROUTES.LOGIN,
		element: <LoginPage />,
	},
]);
