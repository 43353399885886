import { Modal } from 'antd';
import styled from 'styled-components';
import PrimaryButton from '../UI/PrimaryButton';

const StyledModal = styled(Modal)`
	.ant-modal-content {
		background: #070c1e;
		border-radius: 8px;
		padding: 0;
		color: #737283;
	}
	.ant-modal-header {
		background: #091229;
		height: 60px;
		padding: 20px 30px;
	}
	.ant-modal-title {
		color: #d1d4dc;
	}
	.ant-modal-body {
		padding: 10px 30px;
	}
	.ant-modal-close {
		color: #fff;
	}
`;

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	isActive: boolean | undefined;
}
const UpdateStatusWalletModal: React.FC<Props> = ({
	open,
	onClose,
	onSubmit,
	isActive,
}: Props) => {
	return (
		<StyledModal
			title="Update status"
			open={open}
			onCancel={onClose}
			footer={null}
			centered
		>
			<div>
				Do you really want to{' '}
				<strong style={{ color: '#fff' }}>
					{isActive ? 'DEACTIVE' : 'ACTIVE'}
				</strong>{' '}
				this wallet?
			</div>
			<PrimaryButton
				style={{
					width: '100%',
					marginTop: '30px',
					marginBottom: '30px',
				}}
				onClick={onSubmit}
			>
				Confirm
			</PrimaryButton>
		</StyledModal>
	);
};

export default UpdateStatusWalletModal;
