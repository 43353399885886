import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserIdentityClient } from 'user-identity-js';
import SendIcon from '../../Icons/SendIcon';
import PaperclipIcon from '../../Icons/PaperclipIcon';
import styled from 'styled-components';
interface ItemChat {
	id: string;
	uid: string;
	nickName: string;
	message: string;
	timestamp: number;
	avatar: string;
	type: string;
}

const Wrapper = styled('div')`
	width: 356px;
	p {
		margin: 0;
	}
`;
const Header = styled('div')`
	background-color: #091229;
	padding: 20px;
	border-radius: 10px 10px 0px 0px;
`;
const UserWrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Content = styled('div')`
	height: 450px;
	background-color: #070c1e;
	padding: 20px;
	overflow-y: auto;
`;
const ChatBox = styled('div')`
	display: flex;
	padding: 14px 20px;
	align-items: center;
	border-radius: 0px 0px 10px 10px;
	background-color: #091229;
`;
const ReceiveWrapper = styled('div')`
	display: flex;
	margin-bottom: 16px;
`;
const ReceiveContent = styled('div')`
	padding: 16px;
	background-color: rgba(255, 195, 31, 0.1);
	border-radius: 8px;
	font-size: 14px;
	line-height: 28px;
	cursor: pointer;
	width: max-content;
`;
const SendWrapper = styled('div')`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 16px;
`;
const SendContent = styled('div')`
	padding: 16px;
	background-color: #1f2434;
	border-radius: 8px;
	font-size: 14px;
	line-height: 28px;
	cursor: pointer;
	width: max-content;
`;
const Mail = styled('p')`
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #1c84e4;
`;
const UserInfo = styled('div')`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 10px;
`;
const SendIconWrapper = styled('div')`
	cursor: pointer;
`;
const PaperClipWrapper = styled('div')`
	margin-right: 16px;
	width: 20px;
	height: 20px;
	position: relative;
	cursor: pointer;
`;
const StatusWrapper = styled('div')`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: flex-end;
`;
const StatusIcon = styled('div')<{ status: string }>`
	width: 10px;
	height: 10px;
	border-radius: 100%;
	margin-right: 5px;
	background-color: ${(props) =>
		`${props.status?.toLowerCase() === 'online' ? '#03A66D' : 'grey'}`};
`;
const StatusText = styled('p')<{ status: string }>`
	font-size: 14px;
	color: ${(props) =>
		`${props.status?.toLowerCase() === 'online' ? '#03A66D' : 'grey'}`};
`;
const MessageInput = styled('input')`
	background-color: transparent;
	border: none;
	outline: none;
	color: #ffffff;
	width: 100%;
`;
const SubInfoWrapper = styled('div')`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;
const SubInfo = styled('p')`
	font-weight: 400;
	font-size: 14px;
	line-height: 28px;
	color: #737283;
	span {
		color: #ffffff;
	}
`;

interface Props {
	counterpart: string;
	status: string | undefined;
	groupId: string;
}
const Chat = ({ counterpart, status, groupId }: Props) => {
	// const chatRoomClient = useSelector(
	// 	(state: RootState) => state.system.chatRoomClient
	// );
	// const userIdentity = useSelector(
	// 	(state: RootState) => state.system.userIdentity
	// );
	// const userProfile = useSelector((state: RootState) => state.user.userProfile);
	// const toast = useToast();
	// const [chatRoom, setChatRoom] = useState<GroupChatRoom<ChatRoomState>>();
	// const [chatList, setChatList] = useState<ItemChat[]>([]);
	// const [chatIsReady, setChatIsReady] = useState(false);
	const inputRef = useRef<any>();
	const chatContainerRef = useRef<any>();

	// useEffect(() => {
	// 	if (chatRoomClient && groupId) {
	// 		listenEventChat(chatRoomClient, groupId);
	// 	}
	// }, [chatRoomClient, groupId]);

	// useEffect(() => {
	// 	if (chatIsReady && chatRoom && userIdentity) {
	// 		getHistory(chatRoom, userIdentity);
	// 	}
	// }, [chatIsReady, chatRoom, userIdentity]);

	// const listenEventChat = async (
	// 	chatRoomClient: ChatRoomClient,
	// 	groupId: string
	// ) => {
	// 	const chatRoom = chatRoomClient.getGroupChatRoom(groupId);
	// 	await chatRoom.connect();
	// 	setChatRoom(chatRoom);

	// 	chatRoom.onError((code, error) => {
	// 		toast({
	// 			title: 'Error',
	// 			description: error,
	// 			status: 'error',
	// 		});
	// 	});

	// 	chatRoom.onStateChange((state) => {
	// 		setChatIsReady(state.isReady);
	// 	});

	// 	chatRoom.onChatMessage((message) => {
	// 		parseChatMesage(message);
	// 	});
	// };

	// const parseChatMesage = async (message: ChatMessage) => {
	// 	if (!userIdentity) return;
	// 	try {
	// 		const user = await userIdentity.getUser(message.uid);
	// 		const newMessage = {
	// 			id: message.id,
	// 			uid: message.uid,
	// 			nickName: user?.nickName || 'Hidden',
	// 			message: message.message,
	// 			timestamp: message.timestamp * 1000,
	// 			avatar: user.avatar || '/images/avatar.png',
	// 			type: message.type,
	// 		} as ItemChat;
	// 		setChatList((prevChat) => {
	// 			let newList = prevChat.concat([newMessage]);
	// 			newList = uniqBy(newList, 'id');
	// 			return newList;
	// 		});
	// 		scrollToBottomChat();
	// 	} catch (error) {
	// 		console.log('error', error);
	// 	}
	// };

	// const getHistory = async (
	// 	chatRoom: GroupChatRoom<ChatRoomState>,
	// 	userIdentity: UserIdentityClient
	// ) => {
	// 	try {
	// 		const queryParams = {
	// 			limit: 100,
	// 			offset: 0,
	// 		};
	// 		const history = await chatRoom.getChatHistory(queryParams);
	// 		if (history.length > 0) {
	// 			const users = await userIdentity.getUsers({
	// 				ids: history
	// 					.reverse()
	// 					.map((historyItem) => historyItem.uid)
	// 					.toString(),
	// 			});
	// 			const newChatList = history.map((historyItem) => {
	// 				return {
	// 					id: historyItem.id,
	// 					uid: historyItem.uid,
	// 					nickName:
	// 						find(users.data, { id: historyItem.uid })?.nickName || 'Hidden',
	// 					message: historyItem.message,
	// 					timestamp: historyItem.timestamp * 1000,
	// 					avatar:
	// 						find(users.data, { id: historyItem.uid })?.avatar ||
	// 						'/images/avatar.png',
	// 					type: historyItem.type,
	// 				};
	// 			});
	// 			setChatList(newChatList);
	// 			scrollToBottomChat();
	// 		}
	// 	} catch (error) {
	// 		console.log('error', error);
	// 	}
	// };

	// const handleSendMessage = async () => {
	// 	if (inputRef.current) {
	// 		const message = inputRef.current?.value || '';
	// 		if (chatRoom?.isReady() && message) {
	// 			await chatRoom.sendTextMessage(message);
	// 			inputRef.current.value = '';
	// 		}
	// 	}
	// };
	// const handleSendFile = async (files: FileList | null) => {
	// 	if (files && files.length > 0) {
	// 		await chatRoom?.sendFileMessage(files[0]);
	// 	}
	// };
	// const scrollToBottomChat = async () => {
	// 	const scroll = chatContainerRef.current?.scrollHeight;
	// 	chatContainerRef.current?.scrollTo(0, scroll + 600);
	// };
	// const getFileToDisplay = async (
	// 	fileId: string,
	// 	groupId: string,
	// 	messageId: string
	// ) => {
	// 	const res = await chatRoom?.getFile(fileId, groupId);
	// 	if (res) {
	// 		const index = chatList.findIndex((item) => item.id === messageId);
	// 		if (index !== -1) {
	// 			FilerSaver.saveAs(res.url, fileId);
	// 		}
	// 	}
	// };

	return (
		<Wrapper>
			<Header>
				<UserWrapper>
					<UserInfo>
						<img src={'/images/avatar.svg'} alt="avatar" />
						<Mail>{counterpart}</Mail>
					</UserInfo>
					<div>
						<StatusWrapper>
							<StatusIcon status="status" />
							{status && (
								<StatusText status="status">
									{status?.charAt(0)?.toUpperCase() + status?.slice(1)}
								</StatusText>
							)}
						</StatusWrapper>
					</div>
				</UserWrapper>

				{/* <SubInfoWrapper>
					<SubInfo>
						30D Trades: <span>1336</span>
					</SubInfo>
					<SubInfo>
						30D Completion Rate: <span>100%</span>
					</SubInfo>
				</SubInfoWrapper> */}
			</Header>
			<Content ref={chatContainerRef}>
				{/* {chatList.map((item) =>
					item.uid === userProfile?.id ? (
						<Flex key={item.id} justifyContent={'flex-end'} mb={'16px'}>
							<SendContent
								onClick={async () => {
									item.type === 'file' &&
										(await getFileToDisplay(item.message, groupId, item.id));
								}}
							>
								{item.message}
							</SendContent>
						</Flex>
					) : (
						<Flex key={item.id} justifyContent={'flex-start'} mb={'16px'}>
							<ReceiveContent
								onClick={async () => {
									item.type === 'file' &&
										(await getFileToDisplay(item.message, groupId, item.id));
								}}
							>
								{item.message}
							</ReceiveContent>
						</Flex>
					)
				)} */}
				<ReceiveWrapper>
					<ReceiveContent
					// onClick={async () => {
					// 	item.type === 'file' &&
					// 		(await getFileToDisplay(item.message, groupId, item.id));
					// }}
					>
						Note: To release order fast without waiting for SMS OTP, users are
						encouraged to use 2FA authentication method (Google Authenticator).
						To receive assistance fast from Metaniex, please open a chat wtih
						Metaniex Customer Support in your app. Metaniex does NOT actively
						contact users, does not call, does not support on external app.
						Those claiming to be CS of Metaniex on externall app, showing staff
						namecard, or requesting you to scan QR code are all scammers.
					</ReceiveContent>
				</ReceiveWrapper>

				<SendWrapper>
					<SendContent>Okay!</SendContent>
				</SendWrapper>
			</Content>
			<ChatBox>
				<MessageInput
					ref={inputRef}
					placeholder={'Write your message...'}
					// onKeyDown={(event) => event.key === 'Enter' && handleSendMessage()}
				/>
				<PaperClipWrapper>
					<PaperclipIcon style={{ cursor: 'pointer' }} />
					<input
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							opacity: 0,
							zIndex: 1,
							cursor: 'pointer',
						}}
						width={20}
						height={20}
						type="file"
						// onChange={(event) => handleSendFile(event.target.files)}
					/>
				</PaperClipWrapper>
				<SendIconWrapper
				// onClick={handleSendMessage}
				>
					<SendIcon />
				</SendIconWrapper>
			</ChatBox>
		</Wrapper>
	);
};

export default Chat;
