import React from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')`
	background: linear-gradient(
		100.78deg,
		rgba(255, 255, 255, 0.116) 0.27%,
		rgba(255, 255, 255, 0.024) 102.68%
	);
	width: 100%;
	height: 1px;
`;

export default function CustomDivider() {
	return <Wrapper />;
}
