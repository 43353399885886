import React from 'react';

export default function DetailIcon() {
	return (
		<svg
			width={8}
			height={9}
			viewBox="0 0 8 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.144893 8.02331C-0.0482977 7.83239 -0.0482977 7.52284 0.144893 7.33192L3.01061 4.49984L0.144893 1.66776C-0.0482979 1.47683 -0.0482979 1.16729 0.144893 0.976363C0.338083 0.78544 0.651307 0.78544 0.844497 0.976363L3.71021 3.80844C4.0966 4.19029 4.09659 4.80939 3.71021 5.19123L0.844498 8.02331C0.651307 8.21423 0.338083 8.21423 0.144893 8.02331Z"
				fill="#737283"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.14489 8.02331C3.9517 7.83239 3.9517 7.52284 4.14489 7.33192L7.01061 4.49984L4.14489 1.66776C3.9517 1.47683 3.9517 1.16729 4.14489 0.976363C4.33808 0.78544 4.65131 0.78544 4.8445 0.976363L7.71021 3.80844C8.0966 4.19029 8.09659 4.80939 7.71021 5.19123L4.8445 8.02331C4.65131 8.21423 4.33808 8.21423 4.14489 8.02331Z"
				fill="#737283"
			/>
		</svg>
	);
}
