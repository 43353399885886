import React from 'react';

export default function TradingIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.8724 10.0049C13.7583 10.2746 13.629 10.5357 13.485 10.7878C13.4705 10.9828 13.4825 11.2542 13.7419 11.2541C13.7419 11.2541 18.7438 11.243 18.7438 11.243C18.9078 11.2426 19.0293 11.0906 18.9938 10.9305C18.4684 8.56027 16.674 6.66763 14.36 6.00134C14.5727 7.34714 14.4051 8.75475 13.8724 10.0049Z"
				fill="#737283"
			/>
			<path
				d="M13.3572 7.13675C13.4065 -0.224175 2.52355 -1.31251 1.01925 5.78187C0.991793 5.9045 1.08436 6.02155 1.2104 6.02124L6.0111 6.01049C6.11896 6.01025 6.20628 5.92274 6.20628 5.81488L6.20628 5.15131C6.20628 4.93785 6.44247 4.80892 6.62201 4.92438C10.9093 7.86146 10.908 6.43558 6.62204 9.37322C6.4425 9.48872 6.20632 9.35979 6.20632 9.14633L6.20632 8.39681C6.20632 8.28859 6.11846 8.20095 6.01025 8.2012L1.19567 8.21194C1.0722 8.21222 0.97875 8.32563 1.00427 8.44643C2.44726 15.6166 13.4202 14.5446 13.3572 7.13675Z"
				fill="#737283"
			/>
			<path
				d="M12.6248 18.999C15.7203 18.952 18.3011 16.7832 18.9776 13.8828C19.0151 13.7219 18.8921 13.5681 18.727 13.5685L13.7405 13.5797C13.5991 13.58 13.4847 13.6947 13.4847 13.836L13.4847 14.5469C13.4847 14.7497 13.2602 14.8723 13.0896 14.7625L10.9626 13.3949C9.56076 14.2658 7.86408 14.6341 6.22408 14.4313C7.10604 17.1069 9.65336 19.0442 12.6248 18.999Z"
				fill="#737283"
			/>
		</svg>
	);
}
