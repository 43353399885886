import { Modal, notification } from 'antd';
import styled from 'styled-components';
import PrimaryButton from '../UI/PrimaryButton';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

const StyledModal = styled(Modal)`
	.ant-modal-content {
		background: #070c1e;
		border-radius: 8px;
		padding: 0;
		color: #737283;
	}
	.ant-modal-header {
		background: #091229;
		height: 60px;
		padding: 20px 30px;
	}
	.ant-modal-title {
		color: #d1d4dc;
	}
	.ant-modal-body {
		padding: 10px 30px;
	}
	.ant-modal-close {
		color: #fff;
	}
`;

interface Props {
	open: boolean;
	onClose: () => void;
	refresh: () => void;
	configId: string | undefined;
}
const ConfirmModal: React.FC<Props> = ({
	open,
	onClose,
	refresh,
	configId,
}: Props) => {
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const [api, contextHolder] = notification.useNotification();
	const deleteConfig = async () => {
		if (!configId || !metaverseJs) return;
		try {
			const res = await metaverseJs.deleteAccountCurrencyConfig(configId);
			if (res?.id) {
				api.success({
					message: 'Delete config successfully',
				});
				refresh();
				onClose();
			}
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Something went wrong',
			});
			console.log(error);
		}
	};
	return (
		<>
			<StyledModal
				title="Delete Config"
				open={open}
				onCancel={onClose}
				footer={null}
				centered
			>
				<div>
					Do you really want to{' '}
					<strong style={{ color: '#fff' }}>Delete</strong> this config?
				</div>
				<PrimaryButton
					style={{
						width: '100%',
						marginTop: '30px',
						marginBottom: '30px',
					}}
					onClick={deleteConfig}
				>
					Confirm
				</PrimaryButton>
			</StyledModal>
			{contextHolder}
		</>
	);
};

export default ConfirmModal;
