import React from 'react';

export default function HomePage() {
	return (
		<iframe
			width={1000}
			height={1000}
			src="https://api.honorlink.org/api/open?game_id=vs20doghouse&vendor=PragmaticPlay&token=amCINbyYGA5v5630DLBpizhERgaHznKwPNEdlSRI&_=1686221662003840355115308&vendor=pragmaticplay"
		/>
	);
}
