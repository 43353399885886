import React from 'react';

export default function CopyIcon() {
	return (
		<svg
			width={17}
			height={17}
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.9899 0.166626H6.41003C4.82395 0.166626 3.53328 1.45729 3.53328 3.04338V3.49996H3.0767C1.49062 3.49996 0.199951 4.79063 0.199951 6.37671V13.9565C0.199951 15.5426 1.49062 16.8333 3.0767 16.8333H10.6565C12.1135 16.8333 13.3083 15.7401 13.4951 14.3333H13.9898C15.576 14.3333 16.8666 13.0426 16.8666 11.4565V3.04338C16.8666 1.45729 15.576 0.166626 13.9899 0.166626ZM15.2 11.4565C15.2 12.1238 14.6571 12.6666 13.9899 12.6666H13.5333V6.37671C13.5333 4.79063 12.2426 3.49996 10.6565 3.49996H5.19995V3.04338C5.19995 2.37613 5.74278 1.83329 6.41003 1.83329H13.9898C14.6571 1.83329 15.2 2.37613 15.2 3.04338V11.4565Z"
				fill="#737283"
			/>
		</svg>
	);
}
