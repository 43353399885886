import React from 'react';

export default function AdManagementIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.7334 13.8992L14.8801 15.22C14.9418 15.7767 14.6793 16.32 14.2034 16.6175L10.7218 18.7933C10.4151 18.985 10.0309 19.0042 9.70676 18.8433C9.38259 18.6825 9.16509 18.365 9.13259 18.0042L8.98926 16.4325C9.75426 16.38 10.6634 16.1358 11.5909 15.7325C12.6859 15.2558 13.8193 14.5775 14.7334 13.8992Z"
				fill="#737283"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.56763 11.0109L1.99597 10.8676C1.63513 10.8351 1.31763 10.6176 1.1568 10.2934C0.995965 9.96927 1.01513 9.5851 1.2068 9.27844L3.38263 5.79677C3.68013 5.32094 4.22347 5.05844 4.78013 5.1201L6.10097 5.26677C5.42263 6.18094 4.7443 7.31427 4.26763 8.40927C3.8643 9.33677 3.62013 10.2459 3.56763 11.0109Z"
				fill="#737283"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7851 14.7251C11.6092 14.8109 11.4334 14.8926 11.2584 14.9684C10.1492 15.4509 9.07424 15.7017 8.3134 15.5834C8.18257 15.5626 8.06174 15.5017 7.96757 15.4076L4.59257 12.0326C4.4984 11.9384 4.43757 11.8176 4.41674 11.6867C4.2984 10.9259 4.54924 9.85088 5.03174 8.74172C5.10757 8.56672 5.18924 8.39088 5.27507 8.21505L11.7851 14.7251ZM5.8984 7.07005C6.47007 6.11338 7.1209 5.24505 7.68674 4.67922C11.2517 1.11338 14.5426 0.823384 16.3901 1.14838C17.3176 1.31172 17.9234 1.65588 18.1342 1.86588C18.3442 2.07672 18.6884 2.68255 18.8517 3.61005C19.1767 5.45755 18.8867 8.74838 15.3209 12.3134C14.7551 12.8792 13.8867 13.5301 12.9301 14.1017L5.8984 7.07005ZM14.5976 5.40255C13.8217 4.62588 12.5609 4.62588 11.7851 5.40255C11.0092 6.17838 11.0092 7.43922 11.7851 8.21505C12.5609 8.99088 13.8217 8.99088 14.5976 8.21505C15.3742 7.43922 15.3742 6.17838 14.5976 5.40255Z"
				fill="#737283"
			/>
			<path
				d="M4.497 12.923C5.05769 11.5367 6.85133 11.1721 7.90874 12.2295C8.97022 13.291 8.59807 15.0923 7.20285 15.6463L5.81817 16.196C5.28018 16.4096 4.66671 16.2828 4.25741 15.8735C3.84572 15.4619 3.72011 14.8439 3.9384 14.3042L4.497 12.923Z"
				fill="#737283"
			/>
		</svg>
	);
}
