import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import { MetaverseInternalClient } from 'metaverse-js/lib/internal/MetaverseInternalClient';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import { ColumnsType } from 'antd/es/table';
import CustomTable from '../UI/CustomTable';
import EditIcon from '../Icons/EditIcon';
import UpdateCurrencyModal from './UpdateCurrencyModal';
import styled from 'styled-components';

const EditButton = styled.div`
	cursor: pointer;
	&:hover {
		svg path {
			fill: yellow;
		}
	}
`;
const CurrencyConfig = () => {
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const [assets, setAssets] = useState<Asset[]>([]);
	const [selectedAsset, setSelectedAsset] = useState<Asset>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const columns: ColumnsType<Asset> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Currency',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Network',
			dataIndex: 'chain',
			key: 'chain',
		},
		{
			title: 'Max per one day',
			dataIndex: 'maxPerOneDay',
			key: 'maxPerOneDay',
		},
		{
			title: 'Min per one order',
			dataIndex: 'minPerOneOrder',
			key: 'minPerOneOrder',
		},
		{
			title: 'Threshold',
			dataIndex: 'thresholdToReview',
			key: 'thresholdToReview',
		},
		{
			title: 'Flat fee',
			dataIndex: 'flatFee',
			key: 'flatFee',
		},
		{
			title: 'Transaction fee',
			dataIndex: 'transactionFeePercent',
			key: 'transactionFeePercent',
			render: (text) => <div>{text} %</div>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<EditButton
					onClick={() => {
						setSelectedAsset(record);
						setOpenModal(true);
					}}
				>
					<EditIcon />
				</EditButton>
			),
		},
	];

	useEffect(() => {
		getCurrencyConfig(metaverseJs);
	}, [metaverseJs]);

	const getCurrencyConfig = async (
		metaverseJs: MetaverseInternalClient | null
	) => {
		if (!metaverseJs) return;
		try {
			const res = await metaverseJs.getAssets({
				limit: 1000,
			});
			const data = res.data.filter(
				(item) => item.chain !== 'SPOT' && item.chain !== 'FUNDING'
			);
			setAssets(data);
		} catch (error) {
			console.log('getCurrencyConfig', error);
		}
	};
	return (
		<div>
			<CustomTable
				total={assets.length}
				dataSource={assets}
				columns={columns}
			/>
			<UpdateCurrencyModal
				open={openModal}
				onClose={() => setOpenModal(false)}
				currencyDetail={selectedAsset}
				refresh={() => getCurrencyConfig(metaverseJs)}
			/>
		</div>
	);
};

export default CurrencyConfig;
