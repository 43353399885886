import { Form, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAccount } from '../../redux/reducers/auth.slice';
import { setTokenId } from '../../redux/reducers/system.slice';
import { METANIEX_ADMIN } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import {
	BackgroundContainer,
	Container,
	Footer,
	Label,
	LeftBlock,
	LoginBtn,
	LoginWrapper,
	Logo,
	RightBlock,
	StyledInput,
	StyledInputPassword,
	Title,
} from './styles';

const key = 'login';
interface FormFields {
	email: string;
	password: string;
}
const Login: React.FC = () => {
	const [api, contextHolder] = notification.useNotification();
	const navigate = useNavigate();
	const [form] = Form.useForm<FormFields>();

	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const dispatch = useDispatch();
	const onFinish = async (values: FormFields) => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity?.login(values.email, values.password);
			if (res) {
				dispatch(setAccount(res.data));
				if (res.data.idToken) dispatch(setTokenId(res.data.idToken));
				localStorage.setItem(METANIEX_ADMIN, JSON.stringify(res.data));
				navigate(ROUTES.HOME);
			}
		} catch (error: any) {
			api['error']({
				key,
				message: error?.response?.data?.message || 'Unknown error',
			});
		}
	};
	return (
		<LoginWrapper>
			<BackgroundContainer>
				<Container>
					<LeftBlock>
						<Logo src="./images/logo.svg" alt="logo" />
						<Title>Login to your account</Title>
						<Form form={form} onFinish={onFinish} layout="vertical">
							<Form.Item
								name="email"
								required={false}
								label={<Label>Email address</Label>}
								rules={[
									{
										required: true,
										message: 'Please input your email address',
									},
								]}
							>
								<StyledInput placeholder="Enter your email address" />
							</Form.Item>
							<Form.Item
								name={'password'}
								required={false}
								label={<Label>Password</Label>}
								rules={[
									{
										required: true,
										message: 'Please input your password',
									},
								]}
							>
								<StyledInputPassword
									placeholder="Enter your password..."
									iconRender={(visible) =>
										visible ? (
											<EyeTwoTone
												style={{ fontSize: '24px', color: '#091229' }}
											/>
										) : (
											<EyeInvisibleOutlined style={{ fontSize: '24px' }} />
										)
									}
								/>
							</Form.Item>
							<LoginBtn htmlType="submit">Login</LoginBtn>
						</Form>
					</LeftBlock>
					<RightBlock src="./images/space-craft.svg" alt="space-craft" />
				</Container>
			</BackgroundContainer>
			<Footer>Copyright © 2022 Metaniex. All rights reserved</Footer>
			{contextHolder}
		</LoginWrapper>
	);
};

export default Login;
