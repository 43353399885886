import React from 'react';

const SendIcon = () => {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1096_8240)">
				<path
					d="M12.5898 19.9961C12.8145 19.968 13.007 19.8155 13.0833 19.6029L19.9679 0.802471C20.1284 0.361147 19.6349 -0.128321 19.1976 0.0321601L0.397218 6.91681C0.18458 6.99304 0.0321231 7.18562 0.00403884 7.41029C-0.0240454 7.63497 0.0802675 7.85563 0.268833 7.98402L4.51357 10.8125L12.2769 6.91681C12.7382 6.68412 13.316 7.26185 13.0833 7.72323L9.18759 15.4865L12.0161 19.7313C12.1364 19.9118 12.3571 20.0241 12.5898 19.9961Z"
					fill="#737283"
				/>
				<path
					d="M5.28395 14.7166C5.39228 14.8249 5.54473 14.8931 5.70923 14.8931H8.14052L11.2017 8.79883L5.10742 11.86V14.2913C5.10742 14.4598 5.17563 14.6083 5.28395 14.7166Z"
					fill="#737283"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1096_8240">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SendIcon;
