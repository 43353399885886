import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomTable from '../UI/CustomTable';
import CustomInput from '../UI/CustomInput';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { ActionWrapper } from '../KYC';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import { ActionButtonWrapper } from '../KYC';
import DetailIcon from '../Icons/DetailIcon';
import TitlePage from '../UI/TitlePage';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { P2PTradingAdminClient } from 'skychain-p2p-trading-js';
import { PageOptions } from 'user-identity-js/lib/dto';
import { QueryAdOrder } from 'skychain-p2p-trading-js/lib/dto';
import { UserIdentityAdminClient } from 'user-identity-js';
import { format } from 'date-fns';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';

export enum TYPE {
	BUY = 'buy',
	SELL = 'sell',
}

const Wrapper = styled('div')``;

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const Header = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const TypeText = styled('p')<{ type?: AdOrderType }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		`${props.type === AdOrderType.BUY ? '#03a66d' : '#CF304A'}`};
	text-transform: capitalize;
`;

const CryptoWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SymbolText = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	margin: 0px;
`;
// const CryptoNameText = styled('p')`
// 	font-weight: 400;
// 	font-size: 14px;
// 	line-height: 16px;
// 	color: #737283;
// 	margin: 0px;
// `;
const SymbolImage = styled('img')`
	width: 35px;
`;
const Mail = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #1c84e4;
`;
const Title = styled('p')`
	color: #ffffff;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	margin: 0px;
`;
interface Crypto {
	symbol: string;
	name: string;
}

interface DataType {
	id: string;
	userId: string;
	crypto: Crypto;
	email: string;
	amount: string | number;
	limit: number[] | string[];
	type: string;
	createdAt: string;
	status: string;
	fiatCurrency: string;
}

export default function AdManagement() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const p2pTrading = useSelector((state: RootState) => state.system.p2pTrading);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [ads, setAds] = useState<DataType[]>([]);
	const [userQuery, setUserQuery] = useState<QueryAdOrder>();
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [total, setTotal] = useState(0);
	let filterTimeout: any;

	const columns: ColumnsType<DataType> = [
		{
			title: 'User ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Token',
			dataIndex: 'crypto',
			key: 'crypto',
			render: (crypto: Crypto) => (
				<CryptoWrapper>
					<SymbolImage
						src={`/images/tokens/${crypto.symbol.toLowerCase()}.svg`}
						alt={crypto.symbol}
					/>
					<div>
						<SymbolText>{crypto.symbol}</SymbolText>
						{/* <CryptoNameText>{crypto.name}</CryptoNameText> */}
					</div>
				</CryptoWrapper>
			),
		},
		{
			title: 'User',
			dataIndex: 'email',
			key: 'email',
			render: (text: string) => <Mail>{text}</Mail>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: string | number) => <p>{amount}</p>,
		},
		{
			title: 'Limit',
			dataIndex: 'limit',
			key: 'limit',
			render: (limit: number[] | string[], record) => (
				<p>
					{limit[0]} {record.fiatCurrency} - {limit[1]} {record.fiatCurrency}
				</p>
			),
		},
		{
			title: 'Types',
			key: 'type',
			dataIndex: 'type',
			render: (text: AdOrderType) => (
				<TypeText type={text}>{text.split('_')[1]}</TypeText>
			),
		},
		{
			title: 'Created Date',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<ActionWrapper
					onClick={() => {
						navigate(`${ROUTES.AD_MANAGEMENT}/${record['id']}`);
					}}
				>
					<p>Detail</p>
					<ActionButtonWrapper>
						<DetailIcon />
					</ActionButtonWrapper>
				</ActionWrapper>
			),
		},
	];

	useEffect(() => {
		if (p2pTrading && userIdentity) {
			getAds(p2pTrading, userIdentity, userQuery, params);
		}
	}, [p2pTrading, userIdentity, params, userQuery]);

	const getAds = async (
		p2pTrading: P2PTradingAdminClient,
		userIdentity: UserIdentityAdminClient,
		queryAdOrder: QueryAdOrder | undefined,
		params: PageOptions
	) => {
		if (!p2pTrading || !userIdentity) return;
		try {
			const res = await p2pTrading.getAllAdOrders(queryAdOrder, params);
			const userIds = res.data.map((item) => item.user);
			const users = await userIdentity?.getUsers({
				ids: userIds,
			});
			const data: DataType[] = res.data.map((item) => ({
				...item,
				id: item.id || '',
				userId: item.user,
				email: users?.data.find((user) => user.id === item.user)?.email || '',
				crypto: {
					symbol: item.assetSymbol || '',
					name: item.assetSymbol || '',
				},
				amount: item.totalAmount || '',
				limit: [item.minAmount || 0, item.maxAmount || 0],
				type: item.type || '',
				createdAt: item.createdAt
					? format(new Date(item.createdAt * 1000), 'dd/MM/yyyy HH:mm:ss')
					: '',
				status: item.status || '',
				fiatCurrency: item.fiatSymbol || '',
			}));
			setAds(data);
			setTotal(res.meta.totalRecord);
		} catch (error) {
			console.log('getAds', error);
		}
	};
	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			setUserQuery(values);
		}, 500);
	};
	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<Wrapper>
			<TitlePage title={<Title>AD Management</Title>} />
			<Header>
				<Form form={form} onFinish={onFinish} layout="vertical">
					<Filter>
						<CustomInput
							label="Token"
							name={'assetSymbol'}
							inputProps={{
								onChange: () => {
									form.submit();
								},
							}}
						/>
					</Filter>
				</Form>
				<Button type="primary" ghost>
					Export Excel
				</Button>
			</Header>
			<CustomTable
				columns={columns}
				dataSource={ads}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				total={total}
			/>
		</Wrapper>
	);
}
