import { DefaultOptionType } from 'antd/es/select';

export const METANIEX_ADMIN = 'METANIEX_ADMIN';
export const TxStatusOptions: DefaultOptionType[] = [
	{
		label: 'INIT',
		value: 'INIT',
	},
	{
		label: 'VALIDATING',
		value: 'VALIDATING',
	},
	{
		label: 'INVALID',
		value: 'INVALID',
	},
	{
		label: 'WAITING_FOR_APPROVAL',
		value: 'WAITING_FOR_APPROVAL',
	},
	{
		label: 'PROCESSING',
		value: 'PROCESSING',
	},
	{
		label: 'COMPLETED',
		value: 'COMPLETED',
	},
	{
		label: 'FAILED',
		value: 'FAILED',
	},
	{
		label: 'REJECTING',
		value: 'REJECTING',
	},
	{
		label: 'REJECTED',
		value: 'REJECTED',
	},
	{
		label: 'APPROVED',
		value: 'APPROVED',
	},
];
