import { useState } from 'react';
import TabsUI, { TabItem } from '../UI/Tabs';
import CurrencyConfig from './CurrencyConfig';
import UserCurrencyConfig from './UserCurrencyConfig';

enum CONFIG_TAB {
	CURRENCY_CONFIG = 'Currency',
	USER_CURRENCY_CONFIG = 'User-Currency',
}
const tabs: TabItem[] = [
	{
		key: CONFIG_TAB.CURRENCY_CONFIG,
		value: 'Currency Config',
	},
	{
		key: CONFIG_TAB.USER_CURRENCY_CONFIG,
		value: 'User currency config',
	},
];
const WalletConfig = () => {
	const [selectedTab, setSelectedTab] = useState(tabs[0]);
	const handleSelectedTab = (tab: TabItem) => {
		setSelectedTab(tab);
	};
	return (
		<div>
			<TabsUI
				tabs={tabs}
				selectedTab={selectedTab}
				onSelectedTab={handleSelectedTab}
			/>
			{selectedTab.key === CONFIG_TAB.CURRENCY_CONFIG && <CurrencyConfig />}
			{selectedTab.key === CONFIG_TAB.USER_CURRENCY_CONFIG && (
				<UserCurrencyConfig />
			)}
		</div>
	);
};

export default WalletConfig;
