import React from 'react';
import styled from 'styled-components';
import CustomDivider from './CustomDivider';

const Wrapper = styled('div')`
	margin-bottom: 10px;
`;

export default function TitlePage({ title }: { title: React.ReactNode }) {
	return (
		<Wrapper>
			{title}
			<CustomDivider />
		</Wrapper>
	);
}
