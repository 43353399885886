import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import styled from 'styled-components';

const Wrapper = styled('div')`
	position: relative;
`;

const TableWrapper = styled(Table)`
	.ant-table {
		background-color: transparent;
		border-radius: 8px;
		overflow: auto;
	}

	.ant-table-content > table > thead > tr > th {
		background-color: #070c1e;
		border: none;
		color: #737283;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
	.ant-table-content > table > tbody > tr > td {
		background-color: #070c1e;
		border-top: 1px solid #ffffff1e;
		border-bottom: none;
		color: #ffffff;
		/* border-bottom: ; */
	}

	.ant-pagination > .ant-pagination-prev svg path,
	.ant-pagination > .ant-pagination-next svg path,
	.ant-pagination > .ant-pagination-jump-next svg path,
	.ant-pagination > .ant-pagination-jump-prev svg path {
		fill: #ffffff;
	}
	.ant-pagination > .ant-pagination-item > a,
	.ant-pagination
		> .ant-pagination-jump-next
		> a
		> .ant-pagination-item-container
		> .ant-pagination-item-ellipsis,
	.ant-pagination
		> .ant-pagination-jump-prev
		> a
		> .ant-pagination-item-container
		> .ant-pagination-item-ellipsis {
		color: #ffffff !important;
	}
	.ant-pagination > .ant-pagination-item-active {
		background-color: transparent;
		border: none;
	}
	.ant-pagination > .ant-pagination-item-active > a {
		background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
	:where(.css-dev-only-do-not-override-178vi8q).ant-table-wrapper
		.ant-table-tbody
		> tr
		> td.ant-table-cell-row-hover {
		background: #070c1e80;
	}
	:where(.css-dev-only-do-not-override-178vi8q).ant-table-wrapper
		.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before,
	:where(.css-dev-only-do-not-override-178vi8q).ant-table-wrapper
		.ant-table-thead
		> tr
		> td:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		position: static;
	}
	.ant-empty-description {
		color: #ffffff40;
	}
	&& tbody > tr:hover > td {
		background: #0d1635 !important;
	}
`;
const TotalWrapper = styled('div')`
	position: absolute;
	bottom: 0;
`;

interface CustomTableProps {
	columns: ColumnsType<any>;
	dataSource: any[];
	total?: number;
	pageSize?: number;
	onChange?: (pagination: TablePaginationConfig) => void;
}

export default function CustomTable({
	columns,
	dataSource,
	pageSize = 10,
	total = dataSource.length,
	onChange,
}: CustomTableProps) {
	return (
		<Wrapper>
			<TableWrapper
				columns={columns as any[]}
				dataSource={dataSource}
				pagination={{ pageSize, total }}
				onChange={onChange}
			/>
			{total > 0 && (
				<TotalWrapper>
					<p>Total: {total}</p>
				</TotalWrapper>
			)}
		</Wrapper>
	);
}
