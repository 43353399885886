import { Checkbox, CheckboxProps, Form, FormItemProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Label = styled('div')`
	color: #737283;
`;
interface CustomCheckboxProps extends Omit<FormItemProps, 'label'> {
	label?: string;
	checkboxProps?: CheckboxProps;
}

const CustomCheckbox = ({
	label,
	checkboxProps,
	...props
}: CustomCheckboxProps) => {
	return (
		<Form.Item
			valuePropName="checked"
			label={label ? <Label>{label}</Label> : null}
			{...props}
		>
			<Checkbox {...checkboxProps} />
		</Form.Item>
	);
};

export default CustomCheckbox;
