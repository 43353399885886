import React, { useState } from 'react';
import WithdrawProgressFilter from './WithdrawProgressFilter';
import WithdrawProgressTable from './WithdrawProgressTable';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';

const WithdrawProgress = () => {
	const [params, setParams] = useState<TransactionQueryRequest>();

	return (
		<div>
			<WithdrawProgressFilter setParams={setParams} />
			<WithdrawProgressTable txParams={params} />
		</div>
	);
};

export default WithdrawProgress;
