import React from 'react';

export default function ReportIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.05271 17.9803L6.34691 17.8981C6.62988 17.819 6.79517 17.5255 6.7161 17.2425L3.35252 5.20554C3.30506 5.03567 3.19205 4.89161 3.03837 4.80506C2.88469 4.71851 2.70292 4.69655 2.53305 4.74402L2.49506 4.75463C2.1413 4.85347 1.93465 5.22037 2.03349 5.57412L5.39715 17.6111C5.47622 17.8941 5.76973 18.0594 6.05271 17.9803Z"
				fill="#737283"
			/>
			<path
				d="M17.8979 9.56855C16.8312 9.03669 15.8445 8.35781 14.9664 7.55178C14.8926 7.48421 14.8637 7.38051 14.892 7.28453C15.3271 5.63865 15.6502 3.96519 15.8591 2.27565C15.8922 1.99462 15.7406 1.91197 15.5808 2.10483C12.4455 5.8546 7.02055 1.4188 3.7915 4.82693C3.83027 4.89951 3.86077 4.97622 3.88242 5.0556L6.06451 12.8692C9.29906 9.50243 14.7047 13.9107 17.8345 10.1692C17.9943 9.97631 18.0246 9.62365 17.8979 9.56855Z"
				fill="#737283"
			/>
		</svg>
	);
}
