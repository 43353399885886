import { Button, Form, Modal, notification } from 'antd';
import styled from 'styled-components';
import PrimaryButton from '../UI/PrimaryButton';
import { IAddNewMasterWallet } from 'skychain-decentralized-wallet-js/lib/schema';
import CustomInput from '../UI/CustomInput';
import CustomSelect from '../UI/CustomSelect';
import { SUPPORTED_CHAINS } from 'skychain-decentralized-wallet-js/lib/schema';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const StyledModal = styled(Modal)`
	.ant-modal-content {
		background: #070c1e;
		border-radius: 8px;
		padding: 0;
		color: #737283;
	}
	.ant-modal-header {
		background: #091229;
		height: 60px;
		padding: 20px 30px;
	}
	.ant-modal-title {
		color: #d1d4dc;
	}
	.ant-modal-body {
		padding: 10px 30px;
	}
	.ant-modal-close {
		color: #fff;
	}
`;
const Footer = styled.div`
	display: flex;
	gap: 30px;
	align-items: center;
	margin-top: 30px;
	justify-content: center;
`;

interface Props {
	open: boolean;
	onClose: () => void;
	refresh: () => void;
}

const networks: DefaultOptionType[] = [
	{
		label: SUPPORTED_CHAINS.BSC,
		value: SUPPORTED_CHAINS.BSC,
	},
	{
		label: SUPPORTED_CHAINS.ETH,
		value: SUPPORTED_CHAINS.ETH,
	},
	{
		label: SUPPORTED_CHAINS.TRON,
		value: SUPPORTED_CHAINS.TRON,
	},
];

const AddNewWalletModal: React.FC<Props> = ({
	open,
	onClose,
	refresh,
}: Props) => {
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	const decentralizedWallet = useSelector(
		(state: RootState) => state.system.decentralizedWallet
	);
	const onFinish = async (values: IAddNewMasterWallet) => {
		console.log(values);
		if (!decentralizedWallet) return;
		try {
			const res = await decentralizedWallet.createMasterWallet(values);
			if (res.id) {
				api.success({
					message: 'Add new wallet successfully',
				});
				onClose();
				refresh();
			}
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};
	useEffect(() => {
		form.resetFields();
	}, [open]);
	return (
		<StyledModal
			title="Add New Wallet"
			open={open}
			onCancel={onClose}
			footer={null}
			centered
		>
			{contextHolder}
			<Form onFinish={onFinish} layout="vertical" form={form}>
				<CustomInput
					label="Wallet address"
					placeHolder="Enter wallet address"
					name={'address'}
					rules={[
						{
							required: true,
							message: 'This field is required',
						},
					]}
				/>
				<CustomInput
					label="Private key"
					placeHolder="Enter private key"
					name={'privateKey'}
					rules={[
						{
							required: true,
							message: 'This field is required',
						},
					]}
					inputProps={{
						type: 'password',
					}}
				/>
				<CustomInput
					label="Symbol"
					placeHolder="Enter symbol"
					name={'symbol'}
					rules={[
						{
							required: true,
							message: 'This field is required',
						},
					]}
				/>
				<CustomSelect
					label="Network"
					name={'network'}
					options={networks}
					rules={[
						{
							required: true,
							message: 'This field is required',
						},
					]}
					selectProps={{
						style: {
							width: '100%',
						},
					}}
				/>
				<Footer>
					<Button
						type="primary"
						ghost
						style={{
							width: '40%',
							height: 46,
						}}
						onClick={onClose}
					>
						Cancel
					</Button>
					<PrimaryButton
						style={{
							width: '40%',
						}}
						htmlType="submit"
					>
						Confirm
					</PrimaryButton>
				</Footer>
			</Form>
		</StyledModal>
	);
};

export default AddNewWalletModal;
