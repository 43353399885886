import React from 'react';

export default function ConfigIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5919 8.25908L16.4253 8.0258C16.297 7.6004 16.1269 7.18965 15.9174 6.79902L16.5772 5.80937C16.7011 5.62343 16.6766 5.37587 16.5186 5.2179L14.7509 3.45012C14.5929 3.29212 14.3453 3.26762 14.1594 3.39153L13.1697 4.05131C12.7791 3.84181 12.3683 3.67175 11.9429 3.54337L11.7096 2.37681C11.6658 2.15772 11.4734 2 11.25 2H8.74999C8.52655 2 8.33414 2.15772 8.29033 2.37681L8.05705 3.54337C7.63161 3.67175 7.22087 3.84184 6.83027 4.05131L5.84056 3.39153C5.65468 3.26762 5.40709 3.29212 5.24909 3.45012L3.48134 5.2179C3.32331 5.3759 3.29881 5.62346 3.42278 5.80937L4.08256 6.79902C3.87303 7.18965 3.70297 7.6004 3.57459 8.0258L2.40806 8.25908C2.18897 8.30289 2.03125 8.4953 2.03125 8.71874V11.2187C2.03125 11.4422 2.18897 11.6346 2.40809 11.6784L3.57462 11.9117C3.70297 12.337 3.87306 12.7478 4.08259 13.1384L3.42281 14.1281C3.29884 14.314 3.32337 14.5616 3.48137 14.7196L5.24912 16.4873C5.40715 16.6453 5.65468 16.6698 5.84059 16.5459L6.81002 15.8997V12.957C6.0759 12.1756 5.62499 11.1258 5.62499 9.96874C5.62499 7.55249 7.58374 5.59374 9.99998 5.59374C12.4162 5.59374 14.375 7.55249 14.375 9.96874C14.375 11.1258 13.9241 12.1756 13.1899 12.957V15.8997L14.1594 16.5459C14.2387 16.5988 14.3292 16.6247 14.4192 16.6247C14.5402 16.6247 14.6603 16.5779 14.7508 16.4873L16.5186 14.7196C16.6766 14.5616 16.7011 14.314 16.5771 14.1281L15.9173 13.1384C16.1269 12.7478 16.2969 12.3371 16.4253 11.9117L17.5918 11.6784C17.811 11.6346 17.9687 11.4422 17.9687 11.2187V8.71874C17.9687 8.4953 17.811 8.30289 17.5919 8.25908Z"
				fill="#737283"
			/>
			<path
				d="M11.485 7.40025C11.2491 7.2636 10.9497 7.4086 10.9108 7.67841L10.625 9.65615H9.375L9.08925 7.67841C9.05025 7.4086 8.75097 7.2636 8.51506 7.40025C7.59978 7.93041 7.03125 8.91459 7.03125 9.96865C7.03125 11.1109 7.67094 12.1327 8.70065 12.6353L8.75 12.6594V17.5311C8.75 17.79 8.95987 17.9999 9.21875 17.9999H10.7812C11.0401 17.9999 11.25 17.79 11.25 17.5311V12.6594L11.2993 12.6353C12.3291 12.1327 12.9687 11.1109 12.9687 9.96865C12.9687 8.91459 12.4002 7.93041 11.485 7.40025Z"
				fill="#737283"
			/>
		</svg>
	);
}
