import { ColumnsType } from 'antd/es/table';
import React from 'react';
import styled from 'styled-components';
import CustomTable from '../UI/CustomTable';
import { DisplayNameWrapper } from '../UserList';

const Wrapper = styled('div')`
	width: 100%;
	overflow: auto;
`;

export const StatusText = styled('p')<{ status: boolean }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) => `${props.status ? '#03a66d' : '#CF304A'}`};
`;

export interface ReportDataType {
	date: string;
	users: string;
	total_user_orders: string;
	refund_USDT: string;
	volume_profit: string;
	user_balance_USDT: string;
	total_hot_wallet: string;
	hot_wallet: string;
	hot_wallet_USDT_BEP20: string;
	hot_wallet_USDT_TRC20: string;
	deposit_USDT_BEP20: string;
	deposit_USDT_TRC20: string;
	withdrawal_USDT_BEP20: string;
	withdrawal_USDT_TRC20: string;
	withdraw_fee: string;
	exchange_in: string;
	exchange_out_raw: string;
	exchange_out: string;
	exchange_profit: string;
	exchange_fee: string;
	total_orders: string;
	login_count: string;
	rank_commission: string;
	cash_back: string;
	USDT_converted: string;
}

const columns: ColumnsType<ReportDataType> = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'Total Volume',
		dataIndex: 'total_volume',
		key: 'total_volume',
	},
	{
		title: 'Users',
		dataIndex: 'users',
		key: 'users',
	},
	{
		title: 'Total User Orders',
		dataIndex: 'total_user_orders',
		key: 'total_user_orders',
		render: (data) => (
			<DisplayNameWrapper>
				<p>{data}</p>
			</DisplayNameWrapper>
		),
	},
	{
		title: 'Refund USDT',
		dataIndex: 'refund_USDT',
		key: 'refund_USDT',
	},
	{
		title: 'Volume Profit',
		dataIndex: 'volume_profit',
		key: 'volume_profit',
		render: (data) => <StatusText status={true}>{data}</StatusText>,
	},
	{
		title: 'User Balance USDT',
		dataIndex: 'user_balance_USDT',
		key: 'user_balance_USDT',
	},
	{
		title: 'Total Hot Wallet',
		dataIndex: 'total_hot_wallet',
		key: 'total_hot_wallet',
	},
	{
		title: 'Hot Wallet (Metaniex)',
		dataIndex: 'hot_wallet',
		key: 'hot_wallet',
	},
	{
		title: 'Hot Wallet USDT BEP20',
		dataIndex: 'hot_wallet_USDT_BEP20',
		key: 'hot_wallet_USDT_BEP20',
	},
	{
		title: 'Hot Wallet USDT TRC20',
		dataIndex: 'hot_wallet_USDT_TRC20',
		key: 'hot_wallet_USDT_TRC20',
	},
	{
		title: 'Deposit USDT BEP20',
		dataIndex: 'deposit_USDT_BEP20',
		key: 'deposit_USDT_BEP20',
	},
	{
		title: 'Deposit USDT TRC20',
		dataIndex: 'deposit_USDT_TRC20',
		key: 'deposit_USDT_TRC20',
	},
	{
		title: 'Withdrawal USDT BEP20',
		dataIndex: 'withdrawal_USDT_BEP20',
		key: 'withdrawal_USDT_BEP20',
	},
	{
		title: 'Withdrawal USDT TRC20',
		dataIndex: 'withdrawal_USDT_TRC20',
		key: 'withdrawal_USDT_TRC20',
	},
	{
		title: 'Withdraw Fee 1%',
		dataIndex: 'withdraw_fee',
		key: 'withdraw_fee',
	},
	{
		title: 'Exchange In',
		dataIndex: 'exchange_in',
		key: 'exchange_in',
	},
	{
		title: 'Exchange Out Raw',
		dataIndex: 'exchange_out_raw',
		key: 'exchange_out_raw',
	},
	{
		title: 'Exchange Out',
		dataIndex: 'exchange_out',
		key: 'exchange_out',
	},
	{
		title: 'Exchange Profit',
		dataIndex: 'exchange_profit',
		key: 'exchange_profit',
	},
	{
		title: 'Exchange Fee 1%',
		dataIndex: 'exchange_fee',
		key: 'exchange_fee',
	},
	{
		title: 'Total Orders',
		dataIndex: 'total_orders',
		key: 'total_orders',
	},
	{
		title: 'Login Count',
		dataIndex: 'login_count',
		key: 'login_count',
	},
	{
		title: 'Rank Commission',
		dataIndex: 'rank_commission',
		key: 'rank_commission',
	},
	{
		title: 'Cash Back 0.2%',
		dataIndex: 'cash_back',
		key: 'cash_back',
	},
	{
		title: 'USDT Converted',
		dataIndex: 'USDT_converted',
		key: 'USDT_converted',
	},
];

const dataTableItem = {
	date: '5/1/2023',
	total_volume: '372,151.86000000',
	users: '3,286',
	total_user_orders: '254',
	refund_USDT: '14,805.00000',
	volume_profit: '12,771.08900000',
	user_balance_USDT: '368,257.42472076',
	total_hot_wallet: '4,285.33087716',
	hot_wallet: '3,327.94137800',
	hot_wallet_USDT_BEP20: '807.56370717',
	hot_wallet_USDT_TRC20: '149.82579200',
	deposit_USDT_BEP20: '0.00000000',
	deposit_USDT_TRC20: '0.00000000',
	withdrawal_USDT_BEP20: '0.00000000',
	withdrawal_USDT_TRC20: '0.00000000',
	withdraw_fee: '0.00000000',
	exchange_in: '289,680.71999999',
	exchange_out_raw: '337,653.09000000',
	exchange_out: '334,276.56000000',
	exchange_profit: '-44,595.84000000',
	exchange_fee: '3,376.53000000',
	total_orders: '12,161',
	login_count: '365',
	rank_commission: '42,278.22984000',
	cash_back: '2,168.95500000',
	USDT_converted: '43,987.01415000',
};
const Table = () => {
	const dataTable = [];
	for (let i = 0; i < 15; i++) {
		dataTable.push(dataTableItem);
	}
	return (
		<Wrapper>
			<CustomTable
				columns={columns}
				dataSource={dataTable}
				// onChange={onChangeTable}
				// pageSize={params.pageSize}
				// total={total}
			/>
		</Wrapper>
	);
};

export default Table;
