import React from 'react';
import { ROUTES } from '../../routes/config';
import UserListIcon from '../../components/Icons/UserListIcon';
import TransactionIcon from '../../components/Icons/TransactionIcon';
import KYCIcon from '../../components/Icons/KYCIcon';
import AdManagementIcon from '../../components/Icons/AdManagementIcon';
import TradingIcon from '../../components/Icons/TradingIcon';
// import GameTrialIcon from '../../components/Icons/GameTrialIcon';
// import ClaimIcon from '../../components/Icons/ClaimIcon';
// import GameShowIcon from '../../components/Icons/GameShowIcon';
// import FranchiseIcon from '../../components/Icons/FranchiseIcon';
// import GameManagementIcon from '../../components/Icons/GameManagementIcon';
import ReportIcon from '../../components/Icons/ReportIcon';
import ConfigIcon from '../../components/Icons/ConfigIcon';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '../../components/Icons/LogoutIcon';
import { METANIEX_ADMIN } from '../../constants';

const menus = [
	{ label: 'User List', link: ROUTES.USER_LIST, icon: <UserListIcon /> },
	{
		label: 'Hot Wallets',
		link: ROUTES.HOT_WALLETS,
		icon: <TransactionIcon />,
	},
	{
		label: 'Wallet Transactions',
		link: ROUTES.TRANSACTIONS,
		icon: <TransactionIcon />,
	},
	{ label: 'KYC', link: ROUTES.KYC, icon: <KYCIcon /> },
	{
		label: 'AD Management',
		link: ROUTES.AD_MANAGEMENT,
		icon: <AdManagementIcon />,
	},
	{
		label: 'Buy Sell Crypto',
		link: ROUTES.BUY_SELL_CRYPTO,
		icon: <TradingIcon />,
	},
	// {
	// 	label: 'Game Trial Package',
	// 	link: ROUTES.GAME_TRIAL_PACKAGE,
	// 	icon: <GameTrialIcon />,
	// },
	// { label: 'Claim', link: ROUTES.CLAIM, icon: <ClaimIcon /> },
	// { label: 'Game Show', link: ROUTES.GAME_SHOW, icon: <GameShowIcon /> },
	// { label: 'Franchise', link: ROUTES.FRANCHISE, icon: <FranchiseIcon /> },
	// {
	// 	label: 'Game Management',
	// 	link: ROUTES.GAME_MANAGEMENT,
	// 	icon: <GameManagementIcon />,
	// },
	{ label: 'Reports', link: ROUTES.REPORTS, icon: <ReportIcon /> },
	{ label: 'Wallet Config', link: ROUTES.WALLET_CONFIG, icon: <ConfigIcon /> },
];

const Wrapper = styled('div')`
	background: rgba(9, 18, 41, 0.5);
	width: 336px;
`;

export default function SideBar() {
	const navigate = useNavigate();
	const location = useLocation();
	console.log(location);

	const handleSignOut = () => {
		localStorage.removeItem(METANIEX_ADMIN);
		navigate(ROUTES.LOGIN);
	};

	return (
		<Wrapper>
			{menus.map((menuItem) => (
				<MenuItem
					key={menuItem.label}
					label={menuItem.label}
					icon={menuItem.icon}
					active={location.pathname.includes(menuItem.link)}
					onClick={() => {
						navigate(menuItem.link);
					}}
				/>
			))}
			<MenuItem
				label={'Logout'}
				icon={<LogoutIcon />}
				onClick={handleSignOut}
			/>
		</Wrapper>
	);
}

const MenuText = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	color: #737283;
	margin: 0px;
`;

const MenuItemWrapper = styled('div')<{ active?: boolean }>`
	/* width: 100%; */
	padding: 20px 20px 20px 50px;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	svg path {
		fill: ${(props) => (props.active ? '#ffffff' : '')};
	}
	${MenuText} {
		color: ${(props) => (props.active ? '#ffffff' : '')};
	}
	background-color: ${(props) => (props.active ? '#091229' : '')};
	&:hover {
		svg path {
			fill: #ffffff;
		}
	}
	&:hover ${MenuText} {
		color: #ffffff;
	}
`;

interface MenuItemProps {
	label: string;
	icon: React.ReactNode;
	onClick: () => void;
	active?: boolean;
}

const MenuItem = ({ label, icon, onClick, active }: MenuItemProps) => {
	return (
		<MenuItemWrapper onClick={onClick} active={active}>
			{icon}
			<MenuText>{label}</MenuText>
		</MenuItemWrapper>
	);
};
