import React from 'react';

export default function CloseIcon() {
	return (
		<svg
			width={17}
			height={16}
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.0914 4L5.09143 12"
				stroke="#555361"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.09143 4L13.0914 12"
				stroke="#555361"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
