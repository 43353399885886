import React, { useState } from 'react';
import TransactionTable from './TransactionTable';
import TransactionFilter from './TransactionFilter';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';

const Transaction = () => {
	const [params, setParams] = useState<TransactionQueryRequest>();
	return (
		<div>
			<TransactionFilter setParams={setParams} />
			<TransactionTable txParams={params} />
		</div>
	);
};

export default Transaction;
