import React from 'react';

const PaperclipIcon = (props: any) => {
	return (
		<svg
			{...props}
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.7281 2.77777C15.3578 0.40741 11.5008 0.40741 9.13033 2.77777L1.76038 10.1476C0.0673968 11.8408 0.0675082 14.5958 1.76068 16.2889C2.60732 17.1356 3.71907 17.5589 4.83126 17.5588C5.94315 17.5587 7.05542 17.1355 7.90195 16.2889L14.6575 9.53334C15.6735 8.51758 15.6736 6.86456 14.6576 5.84842C13.6416 4.83255 11.9885 4.83259 10.9727 5.84861L6.55493 10.2663C6.21576 10.6054 6.21576 11.1553 6.55485 11.4945C6.8941 11.8337 7.44392 11.8337 7.78316 11.4945L12.2011 7.07677C12.5397 6.73812 13.0907 6.73812 13.4293 7.07665C13.7679 7.41538 13.7679 7.96631 13.4292 8.30496L6.67376 15.0607C5.65777 16.0765 4.00482 16.0767 2.98891 15.0608C1.97304 14.0448 1.97293 12.3918 2.98869 11.3758L10.3586 4.00604C12.0518 2.31301 14.8068 2.31294 16.4999 4.00611C17.3202 4.82627 17.7719 5.91681 17.7719 7.07673C17.7719 8.23669 17.3202 9.32719 16.4998 10.1474L9.13014 17.5174C8.79097 17.8565 8.79097 18.4064 9.13022 18.7456C9.29982 18.9152 9.5221 19 9.74433 19C9.96657 19 10.1889 18.9152 10.3585 18.7456L17.7281 11.3758C18.8764 10.2275 19.5088 8.70078 19.5088 7.0768C19.5089 5.45276 18.8765 3.92603 17.7281 2.77777Z"
				fill="#737283"
			/>
		</svg>
	);
};

export default PaperclipIcon;
