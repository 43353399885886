export const getEnv = (
	variable: string,
	defaultValue?: number | string
): any => {
	return process.env[variable] || defaultValue;
};

export const shortenAddress = (address: string): string => {
	return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const convertStatusToCapitalized = (status: string): string => {
	if (!status) return '';
	const newText = status.split('_').join(' ');
	return newText.charAt(0).toUpperCase() + newText.slice(1).toLowerCase();
};
