import { useSelector } from 'react-redux';
import Body from './Body';
import Title from './Title';
import { RootState } from '../../../redux/store';
import { useParams } from 'react-router-dom';
import { P2PTradingAdminClient } from 'skychain-p2p-trading-js';
import { useEffect, useState } from 'react';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { UserEntity } from 'user-identity-js/lib/entities';
import { UserIdentityAdminClient } from 'user-identity-js';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';

export interface AdDetail extends AdOrderEntity {
	userInfo: UserEntity;
	paymentMethodInfo: PaymentMethodEntity | undefined;
}
export default function AdManagementDetail() {
	const p2pTrading = useSelector((state: RootState) => state.system.p2pTrading);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [adDetail, setAdDetail] = useState<AdDetail>();
	const params = useParams();
	const id = params.id;

	useEffect(() => {
		if (p2pTrading && id && userIdentity) {
			getAdDetail(id, p2pTrading, userIdentity);
		}
	}, [p2pTrading, id, userIdentity]);

	const getAdDetail = async (
		id: string,
		p2pTrading: P2PTradingAdminClient,
		userIdentity: UserIdentityAdminClient
	) => {
		if (!p2pTrading) return;
		try {
			const res = await p2pTrading.getAdOrderDetail(id);
			const userInfo = await userIdentity.getUser(res.data.user);
			const paymentMethod = await p2pTrading.getAllPaymentMethod({
				id: res.data.paymentMethod,
			});
			setAdDetail({
				...res.data,
				userInfo,
				paymentMethodInfo:
					paymentMethod.data.length > 0 ? paymentMethod.data[0] : undefined,
			});
		} catch (error) {
			console.log('getAdDetail', error);
		}
	};

	return (
		<div>
			<Title
				type={adDetail?.type}
				cryptoCurrency={adDetail?.assetSymbol || ''}
				fiatCurrency={adDetail?.fiatSymbol || ''}
			/>
			{adDetail && <Body data={adDetail} />}
		</div>
	);
}
