import React from 'react';
import TabsUI, { TabItem } from '../UI/Tabs';
import styled from 'styled-components';
import Transaction from './Transaction';
import WithdrawProgress from './WithdrawProgress';

const Wrapper = styled('div')`
	margin-top: 30px;
`;

const Content = styled('div')`
	margin-top: 20px;
`;

enum TRANSACTION_TAB {
	DEPOSIT = 'Deposit',
	WITHDRAW = 'Withdraw',
}

const tabs: TabItem[] = [
	{
		key: TRANSACTION_TAB.DEPOSIT,
		value: 'Deposit',
	},
	// {
	// 	key: TRANSACTION_TAB.PLACE_GAME,
	// 	value: 'Place Game',
	// },
	{
		key: TRANSACTION_TAB.WITHDRAW,
		value: 'Withdraw',
	},
];
const Body = () => {
	const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
	const handleSelectedTab = (tab: TabItem) => {
		setSelectedTab(tab);
	};
	return (
		<Wrapper>
			<TabsUI
				tabs={tabs}
				selectedTab={selectedTab}
				onSelectedTab={handleSelectedTab}
			/>
			<Content>
				{selectedTab.key === TRANSACTION_TAB.DEPOSIT && <Transaction />}
				{/* {selectedTab.key === TRANSACTION_TAB.PLACE_GAME && <PlaceGame />} */}
				{selectedTab.key === TRANSACTION_TAB.WITHDRAW && <WithdrawProgress />}
			</Content>
		</Wrapper>
	);
};

export default Body;
