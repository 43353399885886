import React from 'react';
import CopyIcon from '../Icons/CopyIcon';
import styled from 'styled-components';
import { notification } from 'antd';

const Wrapper = styled('div')`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
`;

export default function Copy({
	text,
	isShowText = false,
}: {
	text: string;
	isShowText?: boolean;
}) {
	const [api, contextHolder] = notification.useNotification();

	const handleCopy = () => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		api['info']({
			message: 'Copied',
			key: 'copy',
		});
	};
	return (
		<>
			<Wrapper onClick={handleCopy}>
				{isShowText && text}
				<CopyIcon />
			</Wrapper>
			{contextHolder}
		</>
	);
}
