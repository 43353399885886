import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface TabItem {
	key: string;
	value: string;
}

export interface PropsType {
	tabs: TabItem[];
	selectedTab: TabItem;
	onSelectedTab: (tab: TabItem) => void;
}

const TabContainer = styled('div')`
	position: relative;
	width: 100%;
	border-bottom: 1px solid #ffffff1e;
`;

const TabList = styled('ul')`
	display: flex;
	justify-content: space-around;
	height: 40px;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
	@media (min-width: 768px) {
		margin-right: 100px;
		justify-content: flex-start;
	}
`;
const TabItem = styled('li')<{ active: boolean }>`
	padding: 0 10px;
	margin-right: 0;
	font-size: 14px;
	@media (min-width: 768px) {
		font-size: 16px;
		margin-right: 100px;
	}
	cursor: pointer;
	color: ${(props) => (props.active ? 'white' : '#737283')};
	text-transform: capitalize;
	transition: 0.3s ease-in-out;
`;

const TabInkBar = styled('div')`
	position: absolute;
	bottom: 0;
	background: #ffe036;
	border-radius: 14px 14px 0px 0px;
	height: 2px;
	transition: 0.3s ease-in-out;
`;

const TabsUI = ({ tabs, onSelectedTab, selectedTab }: PropsType) => {
	const [offsetWidthTab, setOffsetWidthTab] = useState<number>(0);
	const [offsetLeftTab, setOffsetLeftTab] = useState<number>(0);

	const tabRef = useRef<HTMLLIElement[]>([]);

	const onClickTab = (e: any, tab: TabItem) => {
		setOffsetWidthTab(e.currentTarget.offsetWidth);
		setOffsetLeftTab(e.currentTarget.offsetLeft);
		onSelectedTab(tab);
	};

	useEffect(() => {
		if (tabRef.current[0]) {
			setOffsetWidthTab(tabRef.current[0].offsetWidth);
			setOffsetLeftTab(tabRef.current[0].offsetLeft);
			onSelectedTab(tabs[0]);
		}
	}, [tabRef.current[0]]);
	return (
		<TabContainer>
			<TabList>
				{tabs.map((tab) => {
					const active = selectedTab.key === tab.key;
					return (
						<TabItem
							active={active}
							ref={(ref) => ref && tabRef.current.push(ref)}
							onClick={(e) => onClickTab(e, tab)}
							key={tab.key}
						>
							{tab.value}
						</TabItem>
					);
				})}
			</TabList>
			<TabInkBar
				style={{
					width: `${offsetWidthTab}px`,
					left: `${offsetLeftTab}px`,
				}}
			/>
		</TabContainer>
	);
};

export default React.memo(TabsUI);
