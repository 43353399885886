import { Form, FormItemProps, Input, InputProps } from 'antd';
import styled from 'styled-components';

interface CustomInputProps extends Omit<FormItemProps, 'label'> {
	inputProps?: InputProps;
	label?: string;
	placeHolder?: string;
}
const StyledInput = styled(Input)`
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	height: 38px;
	color: #fff;
	&::placeholder {
		color: #737283;
	}
	.ant-input {
		background: transparent;
		color: #fff;
	}
	.ant-input-clear-icon {
		color: #fff;
	}
`;
const Label = styled('div')`
	color: #737283;
`;
const CustomInput = ({
	inputProps,
	label,
	placeHolder,
	...props
}: CustomInputProps) => {
	return (
		<Form.Item label={label ? <Label>{label}</Label> : null} {...props}>
			<StyledInput {...inputProps} placeholder={placeHolder || ''} />
		</Form.Item>
	);
};

export default CustomInput;
