import React from 'react';

export default function KYCIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.8971 7.70019C14.8319 7.70019 13.1528 6.02118 13.1528 3.95593C13.1528 3.71945 13.1765 3.49086 13.2199 3.27014H1.64419C0.816509 3.27014 0.146484 3.99929 0.146484 4.89791V15.7957C0.146484 16.6943 0.816509 17.4234 1.64419 17.4234H4.99825C4.9076 17.6284 4.7263 17.9082 4.38735 18.2117H3.68973C3.47296 18.2117 3.2956 18.3891 3.2956 18.6058C3.2956 18.8226 3.47296 19 3.68973 19H14.1382C14.355 19 14.5323 18.8226 14.5323 18.6058C14.5323 18.3891 14.355 18.2117 14.1382 18.2117H13.4406C13.1016 17.9082 12.9203 17.6284 12.8297 17.4234H16.1837C17.0075 17.4234 17.6814 16.6943 17.6814 15.7957V7.62136C17.4292 7.6726 17.1651 7.70019 16.8971 7.70019ZM11.7458 6.95528C12.8375 6.95528 13.7243 7.84208 13.7243 8.93382C13.7243 10.0256 12.8375 10.9124 11.7458 10.9124C10.6541 10.9124 9.76725 10.0256 9.76725 8.93382C9.76725 7.84208 10.6541 6.95528 11.7458 6.95528ZM6.08212 6.95528C7.17386 6.95528 8.06066 7.84208 8.06066 8.93382C8.06066 10.0256 7.17386 10.9124 6.08212 10.9124C4.99037 10.9124 4.10357 10.0256 4.10357 8.93382C4.10357 7.84208 4.99037 6.95528 6.08212 6.95528ZM14.5757 13.7383H3.25225C3.03547 13.7383 2.85811 13.5649 2.85811 13.3442C2.85811 12.3273 3.33501 11.4169 4.07204 10.8257C4.57653 11.3617 5.28991 11.6967 6.08212 11.6967C6.87432 11.6967 7.5877 11.3617 8.09219 10.8257C8.4272 11.0976 8.70704 11.4326 8.91199 11.8149C9.12088 11.4326 9.40071 11.0937 9.73572 10.8257C10.2402 11.3617 10.9536 11.6967 11.7458 11.6967C12.538 11.6967 13.2514 11.3617 13.7559 10.8257C14.4929 11.4169 14.9698 12.3273 14.9698 13.3442C14.9698 13.5649 14.7924 13.7383 14.5757 13.7383Z"
				fill="#737283"
			/>
			<path
				d="M16.8969 1C15.2652 1 13.9409 2.32823 13.9409 3.95599C13.9409 5.5877 15.2652 6.91198 16.8969 6.91198C18.5286 6.91198 19.8529 5.5877 19.8529 3.95599C19.8529 2.32823 18.5286 1 16.8969 1ZM18.1897 3.50668L16.7314 4.96497C16.6525 5.0438 16.554 5.07927 16.4515 5.07927C16.3491 5.07927 16.2505 5.03986 16.1717 4.96497L15.6002 4.39348C15.4465 4.23977 15.4465 3.99146 15.6002 3.83775C15.7539 3.68404 16.0022 3.68404 16.1559 3.83775L16.4515 4.13335L17.63 2.9549C17.7837 2.80118 18.032 2.80118 18.1857 2.9549C18.3394 3.10861 18.3434 3.35297 18.1897 3.50668Z"
				fill="#737283"
			/>
		</svg>
	);
}
