import React, { useEffect } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import NavTop from './NavTop';
import styled from 'styled-components';
import SideBar from './SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount } from '../../redux/reducers/auth.slice';
import { setTokenId } from '../../redux/reducers/system.slice';
import { RootState } from '../../redux/store';

const Wrapper = styled('div')`
	width: 100%;
	min-height: 100vh;
	background-color: #050713;
`;

const BodyWrapper = styled('div')`
	width: 100%;
	display: flex;
`;
const RightBlock = styled('div')`
	padding: 20px;
	flex: 1;
	width: calc(100% - 336px);
`;

const Banner = styled('div')`
	background-image: url('/images/banner-bg.svg');
	height: 160px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	padding: 30px 100px;
	box-sizing: border-box;
	position: relative;
	background-size: cover;
	margin-bottom: 40px;
`;

const Info = styled('div')`
	display: flex;
	align-items: center;
	gap: 30px;
`;
const DefaultAvatar = styled('img')`
	width: 100px;
	height: 100x;
	border-radius: 50%;
	object-fit: contain;
`;

const Detail = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: flex-start;
`;

const Email = styled('div')`
	font-size: 14px;
	line-height: 16px;
	/* or 115% */

	color: #737283;
`;
const Name = styled('div')`
	font-size: 18px;
	line-height: 16px;
	/* or 89% */

	color: #ffffff;
`;
const FullName = styled(Email)``;

const Monitor = styled('img')`
	position: absolute;
	top: -20px;
	right: 0;
	bottom: 0;
`;
const Footer = styled.div`
	background: #09122980;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #bdbcc7;
	width: 100%;
`;
const Content = styled.div`
	min-height: 90vh;
`;

export default function MainLayout() {
	const user: any = useLoaderData();
	const dispatch = useDispatch();
	const account = useSelector((state: RootState) => state.auth.account);

	useEffect(() => {
		if (user) {
			dispatch(setAccount(user));
			user.idToken && dispatch(setTokenId(user.idToken));
		}
	}, [user]);

	return (
		<Wrapper>
			<NavTop />
			<BodyWrapper>
				<SideBar />
				<RightBlock>
					<Banner>
						<Info>
							<DefaultAvatar src="./images/default-avatar.svg"></DefaultAvatar>
							<Detail>
								<FullName>Metaniex_32055017</FullName>
								<Name>Admin</Name>
								<Email>{account?.email}</Email>
							</Detail>
						</Info>
						<Monitor src="/images/monitor.svg" />
					</Banner>
					<Content>
						<Outlet />
					</Content>
				</RightBlock>
			</BodyWrapper>
			<Footer>Copyright © 2022 Metaniex. All rights reserved</Footer>
		</Wrapper>
	);
}
