import { Modal, Radio, RadioChangeEvent, Space, notification } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserStatus } from 'user-identity-js/lib/entities/user.entity';
import PrimaryButton from '../UI/PrimaryButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface UpdateUserStatusModalProps {
	open: boolean;
	onClose: () => void;
	defaultValue?: UserStatus;
	userId: string;
	refresh: () => void;
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		background: #070c1e;
		border-radius: 8px;
		padding: 0;
		color: #737283;
	}
	.ant-modal-header {
		background: #091229;
		height: 60px;
		padding: 20px 30px;
	}
	.ant-modal-title {
		color: #d1d4dc;
	}
	.ant-modal-body {
		padding: 10px 30px;
	}
	.ant-modal-close {
		color: #fff;
	}
`;

const StyledRadio = styled(Radio)`
	:where(.css-dev-only-do-not-override-178vi8q).ant-radio-wrapper {
		color: #737283;
	}
`;
const UserStatuses = [
	UserStatus.ACTIVED,
	UserStatus.BANNED,
	UserStatus.INACTIVED,
];
const UpdateUserStatusModal: React.FC<UpdateUserStatusModalProps> = ({
	open,
	onClose,
	defaultValue,
	userId,
	refresh,
}: UpdateUserStatusModalProps) => {
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [api, contextHolder] = notification.useNotification();

	const [value, setValue] = useState<UserStatus>();
	const onChange = (e: RadioChangeEvent) => {
		setValue(e.target.value);
	};
	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const updateUser = async () => {
		if (!userIdentity || !userId || !value) return;
		try {
			const res = await userIdentity.changeUserStatus(userId, value);
			if (res) {
				onClose();
				refresh();
				api['success']({
					message: 'Update user status successfully',
				});
			}
		} catch (error) {
			api['error']({
				message: 'Update user status failed',
			});
		}
	};
	return (
		<StyledModal
			title="Update User"
			open={open}
			onCancel={onClose}
			footer={null}
		>
			<Radio.Group onChange={onChange} value={value}>
				<Space direction="vertical">
					{UserStatuses.map((status) => (
						<StyledRadio key={status} value={status}>
							{status}
						</StyledRadio>
					))}
				</Space>
			</Radio.Group>
			<PrimaryButton
				style={{
					width: '100%',
					marginTop: '30px',
					marginBottom: '30px',
				}}
				onClick={updateUser}
			>
				Confirm
			</PrimaryButton>
			{contextHolder}
		</StyledModal>
	);
};

export default UpdateUserStatusModal;
