import React from 'react';
import Table from './Table';
import Filter from './Filter';

const Report = () => {
	return (
		<div>
			<Filter />
			<Table />
		</div>
	);
};

export default Report;
