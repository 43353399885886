import React from 'react';

export default function MoreIcon() {
	return (
		<svg
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_890_779)">
				<path
					d="M10.5 1.67188C12.5369 1.6727 14.5109 2.37768 16.0875 3.66738C17.6642 4.95707 18.7464 6.75217 19.151 8.74851C19.5555 10.7449 19.2575 12.8197 18.3074 14.6214C17.3572 16.4232 15.8134 17.841 13.9375 18.6348C11.78 19.5464 9.34875 19.5637 7.17853 18.6828C5.00831 17.8019 3.27692 16.095 2.36523 13.9375C1.45355 11.78 1.43626 9.34875 2.31717 7.17853C3.19808 5.00831 4.90503 3.27692 7.0625 2.36523C8.14997 1.90504 9.31917 1.66921 10.5 1.67188ZM10.5 0.5C4.97734 0.5 0.5 4.97734 0.5 10.5C0.5 16.0227 4.97734 20.5 10.5 20.5C16.0227 20.5 20.5 16.0227 20.5 10.5C20.5 4.97734 16.0227 0.5 10.5 0.5Z"
					fill="#737283"
				/>
				<circle cx={6} cy="10.5" r="1.5" fill="#737283" />
				<circle cx={11} cy="10.5" r="1.5" fill="#737283" />
				<circle cx={16} cy="10.5" r="1.5" fill="#737283" />
			</g>
			<defs>
				<clipPath id="clip0_890_779">
					<rect width={21} height={21} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
