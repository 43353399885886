import React from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')`
	background: rgba(9, 18, 41, 0.5);
	padding: 20px 50px;
`;

const LogoWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 15px;
`;

export default function NavTop() {
	return (
		<Wrapper>
			<LogoWrapper>
				<img src="/images/logo.svg" alt="logo" />
				<img src="/images/admin-tag.svg" alt="admin tag" />
			</LogoWrapper>
		</Wrapper>
	);
}
