import React from 'react';

export default function LogoutIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.5 12.6799C14.1267 12.6799 13.8267 12.9799 13.8267 13.3532V14.6932C13.8267 14.9533 13.6134 15.1666 13.3467 15.1666H10.7867V4.82658H13.3467C13.6134 4.82658 13.8267 5.03992 13.8267 5.29993V6.72657C13.8267 7.0999 14.1267 7.39991 14.5 7.39991C14.8733 7.39991 15.18 7.0999 15.18 6.72657V5.29993C15.18 4.29326 14.36 3.47323 13.3467 3.47323H10.76C10.6319 2.63299 9.77712 1.92434 8.78003 2.1399L3.28666 3.36658C2.52669 3.53992 2 4.19992 2 4.97323V15.0266C2 15.8066 2.52669 16.4599 3.28666 16.6333L8.78003 17.8599C9.72871 18.0706 10.6268 17.4084 10.76 16.5199H13.3467C14.36 16.5199 15.18 15.6999 15.18 14.6932V13.3532C15.18 12.9799 14.8733 12.6799 14.5 12.6799ZM7.14001 9.99992C7.14001 9.51323 7.54 9.11325 8.02669 9.11325C8.13334 9.11325 8.22668 9.13327 8.32003 9.16659C8.66667 9.28659 8.91333 9.61325 8.91333 9.99992C8.91333 10.3866 8.66667 10.7133 8.32003 10.8333C8.22668 10.8666 8.13334 10.8866 8.02669 10.8866C7.54 10.8866 7.14001 10.4866 7.14001 9.99992Z"
				fill="#737283"
			/>
			<path
				d="M17.722 9.44756L16.1355 8.25436C15.9253 8.10519 15.6542 8.07809 15.4236 8.19332C15.1932 8.30856 15.0507 8.53911 15.0507 8.79672V9.31877H13.1863C12.8134 9.31877 12.5083 9.62387 12.5083 9.99675C12.5083 10.3697 12.8134 10.6747 13.1863 10.6747H15.0507V11.19C15.0507 11.4476 15.1932 11.6849 15.4236 11.8002C15.6542 11.9155 15.9321 11.8883 16.1355 11.7323L17.7288 10.5324C17.8983 10.4035 18 10.2069 18 9.99C18 9.77983 17.8983 9.57638 17.722 9.44756Z"
				fill="#737283"
			/>
		</svg>
	);
}
