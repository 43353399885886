import React from 'react';

export default function ExpandIcon() {
	return (
		<svg
			width={19}
			height={19}
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_1294_3106)">
				<path
					d="M15 0H3C2.20457 0.000730783 1.44194 0.317036 0.879487 0.879487C0.317036 1.44194 0.000730783 2.20457 0 3V15C0.000730783 15.7954 0.317036 16.5581 0.879487 17.1205C1.44194 17.683 2.20457 17.9993 3 18H15C15.7954 17.9993 16.5581 17.683 17.1205 17.1205C17.683 16.5581 17.9993 15.7954 18 15V3C17.9993 2.20457 17.683 1.44194 17.1205 0.879487C16.5581 0.317036 15.7954 0.000730783 15 0ZM16.8 15C16.7995 15.4772 16.6096 15.9347 16.2722 16.2722C15.9347 16.6096 15.4772 16.7995 15 16.8H3C2.52278 16.7995 2.06525 16.6096 1.7278 16.2722C1.39036 15.9347 1.20054 15.4772 1.2 15V3C1.20054 2.52278 1.39036 2.06525 1.7278 1.7278C2.06525 1.39036 2.52278 1.20054 3 1.2H15C15.4772 1.20054 15.9347 1.39036 16.2722 1.7278C16.6096 2.06525 16.7995 2.52278 16.8 3V15Z"
					fill="white"
				/>
				<path
					d="M7.3759 9.7756L4.8001 12.3514V11.3998C4.8001 11.2407 4.73688 11.0881 4.62436 10.9755C4.51184 10.863 4.35923 10.7998 4.2001 10.7998C4.04097 10.7998 3.88836 10.863 3.77583 10.9755C3.66331 11.0881 3.6001 11.2407 3.6001 11.3998V13.7998C3.6007 13.9588 3.66411 14.111 3.7765 14.2234C3.88889 14.3358 4.04115 14.3992 4.2001 14.3998H6.6001C6.75923 14.3998 6.91184 14.3366 7.02436 14.2241C7.13688 14.1115 7.2001 13.9589 7.2001 13.7998C7.2001 13.6407 7.13688 13.4881 7.02436 13.3755C6.91184 13.263 6.75923 13.1998 6.6001 13.1998H5.6485L8.2243 10.624C8.33359 10.5108 8.39407 10.3593 8.3927 10.202C8.39134 10.0446 8.32823 9.89416 8.21699 9.78291C8.10574 9.67167 7.95526 9.60857 7.79794 9.6072C7.64062 9.60583 7.48906 9.66631 7.3759 9.7756Z"
					fill="white"
				/>
				<path
					d="M14.0291 3.6463C13.9565 3.616 13.8787 3.6003 13.8 3.6001H11.4C11.2409 3.6001 11.0883 3.66331 10.9758 3.77583C10.8632 3.88836 10.8 4.04097 10.8 4.2001C10.8 4.35923 10.8632 4.51184 10.9758 4.62436C11.0883 4.73688 11.2409 4.8001 11.4 4.8001H12.3516L9.77582 7.3759C9.71852 7.43125 9.67281 7.49745 9.64136 7.57066C9.60992 7.64386 9.59337 7.72259 9.59267 7.80226C9.59198 7.88193 9.60716 7.96093 9.63733 8.03467C9.6675 8.10841 9.71205 8.1754 9.76839 8.23173C9.82472 8.28807 9.89171 8.33262 9.96545 8.36279C10.0392 8.39296 10.1182 8.40814 10.1979 8.40745C10.2775 8.40676 10.3563 8.3902 10.4295 8.35876C10.5027 8.32731 10.5689 8.2816 10.6242 8.2243L13.2 5.6485V6.6001C13.2 6.75923 13.2632 6.91184 13.3758 7.02436C13.4883 7.13688 13.6409 7.2001 13.8 7.2001C13.9592 7.2001 14.1118 7.13688 14.2243 7.02436C14.3368 6.91184 14.4 6.75923 14.4 6.6001V4.2001C14.3996 4.08153 14.3641 3.96573 14.2982 3.86721C14.2322 3.76869 14.1386 3.69185 14.0291 3.6463Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1294_3106"
					x={0}
					y={0}
					width={19}
					height={19}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx={1} dy={1} />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1294_3106"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1294_3106"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
}
